import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Mail from "../static/svg/icon-mail.inline.svg";
import Call from "../static/svg/icon-call.inline.svg";
import Calendar from "../static/svg/icon-calendar.inline.svg";

import LayoutSub from "../components/layoutSub";
import SEO from "../components/seo";
import { HookForm } from "../components/hookForm";


import LineLogo from "../static/svg/line.inline.svg";



function Contact({ location, pageContext }) {
  const { site } = useStaticQuery(graphql`
  query SiteContactQuery {
    site {
      siteMetadata {
        postalcode,
        address,
        tel,
        kpostalcode,
        kaddress,
        kaddress2,
        ktel
      }
    }
  }
`);


  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "ご予約・お問い合わせ";
  const description = "西荻窪の整体院・ぺんぎん堂へのご予約お問い合わせページ。肩こり・首痛・頭痛などにお困りの方、お気軽にお問い合わせください。";

  
  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

  return (


    <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop} >
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
      />

      <section>
        <h1 className=" my-2 heading-b">ご予約・お問い合わせ</h1>

        <p className="mb-0">お電話受付時間：<span className="inline sm:hidden"><br />&nbsp;&nbsp;</span>平日・土曜日&nbsp;10:00 ~ 18:00</p>
        <div className="sm:pointer-events-none">
        <a href={`tel:${site.siteMetadata.tel}`.replace(/\s+/g, "")}>
          <p className="text-2xl text-darkteal font-semibold underline sm:no-underline my-0 ">
            <Call className="w-6 h-6 inline pb-0.5 ml-1" />
            {site.siteMetadata.tel}
          </p>
        </a>
        </div>
        <p>施術中などで、お電話に出れない場合がございます。留守番電話にメッセージをお願いいたします。折り返し、ご連絡いたします。</p>
        <p>※当日のご予約はお電話のみ受け付けております。</p>

        <AnchorLink to="/contact/#form" >
          <button type='button' className="bg-c-main text-c-base hover-hover:hover:bg-c-acc active:bg-c-acc text-center my-4 py-2 px-4 rounded font-semibold shadow">

            <Mail className="h-6 w-6 inline-block mr-2 align-bottom" />
            ウェブからのお問い合わせ

          </button>
        </AnchorLink>

        <p className="mb-0.5">LINEでもお問い合わせを受け付けています。</p>
        <p className="inline-block align-middle mb-0.5">LINE ID:@413srlsz&nbsp;
          <a href='https://lin.ee/7RK303'>
            <span className="inline-block align-middle">
              <LineLogo
                height="36"
                width="116" /></span>
          </a>
        </p>
        <p>① ボタンタップで友達追加して<br />
          ② メッセージ入力して送信してください
        </p>
        
      {/*  <div id="form" className="my-8 -mt-14 pt-14 md:-mt-16 md:pt-16"> */}
      <div id="form" className="my-8 pt-8 md:pt-16">
        <HookForm />
        </div>

      </section>
    </LayoutSub>
      );
}

export function Head() {
  return (
    <>
      <script async src="https://s.yimg.jp/images/listing/tool/cv/ytag.js"></script>
      <script>
        {`
          {
          window.yjDataLayer = window.yjDataLayer || [];
          function ytag() { yjDataLayer.push(arguments); }
          ytag({"type":"ycl_cookie"});
          }
        `}
      </script>

      <script async>
        {`
          { 
            ytag({
              "type": "yss_conversion",
              "config": {
              "yahoo_conversion_id": "1001128299",
              "yahoo_conversion_label": "9VvUCMn2zdMBEKvCuKwC",
              "yahoo_conversion_value": "0"
              }
            });
          }
        `}
</script>
    </>
  )
}

export default Contact;

