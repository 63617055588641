import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import Arrow from "../static/svg/arrow.inline.svg";

import LayoutLp from "../components/layoutLp";
import LpHolisticHeroImg from "../components/lp-holisticHeroImage";
import SEO from "../components/seo";
import VoiceShortHipJ from "../components/voiceShortHipJ";
import HookFormsLp from "../components/hookFormsLp";

import InfoNishiogi from "../components/infoNishiogi";
import InfoNishiogiShort from "../components/infoNishiogiShort";

import Cta from "../components/cta";
import Recommend from "../components/recommend";

import Divider from "../static/svg/divider.inline.svg"

import PenLogo from "../static/svg/pen-logo.inline.svg";



function IndexPage({ location }) {
  const { pathname } = location;

  const pagetitle = "西荻窪の整体≪ぺんぎん堂≫";
  const description = "西荻窪から徒歩3分のアメリカ式整体・オステオパシ―専門院。なかなかとれないコリや痛みにお困りの方へ";

  return (
    <LayoutLp>
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
        isLp
      />
      <LpHolisticHeroImg />


      <section className="text-center mx-5">
        <p className="mt-10 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><span className="marker">あなたの未来はどちらですか？</span></p>

        <p className="mt-5">

          長年、股関節の痛みに悩んでいたけれど、ついに痛みから解放されたA子さん。<br />痛みを気にせず、職場で活き活きと働き、「もう痛みのことを考えなくていいなんて夢みたい&#x1f604;」と笑顔を取り戻すことができました。
        </p>
        <p className="mt-5">
          「そのうち良くなるだろう」と自己流のケアや湿布に頼り続けたB子さん。<br />「もっと早く正しいケアを受けていれば…」と後悔する日々を送っています。
        </p>

        <p className="mb-5">ふたりの違いを生む決定的な要因は何でしょうか？&#x1f914;</p>


        <div className="text-left mx-1 md:mx-4">
          {/*
          <div className="w-full max-w-sm mx-auto mb-12">
            <h1>
              <StaticImage
                src="../images/fucho-reason.png"
                alt="不調の原因はどこにある？"
                placeholder="blurred"
                layout="constrained"
              />
            </h1>
          </div>
          */}
          <p className="mt-10 mb-5 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><span className="marker">痛みから解放されるために必要なこと</span></p>


          <p className="sm:mt-3">股関節の痛みを根本から解消し、再び自由に動ける未来を手に入れるためには、いくつかの重要な条件があります。その条件は、</p>
          <div className="bg-emerald-50 px-3 mx-3 rounded-xl">
            <ul className="list-decimal  py-3">
              <li className="my-2"><span className="marker">痛みの根本原因を特定すること</span><br />痛みの原因が筋肉の緊張なのか、骨格&#x1f9b4;の歪みなのか、筋膜の滑りが悪くなっているのか、それとも内臓の影響なのかを正しく見極める必要があります。</li>
              <li className="my-2"><span className="marker">解剖学的に正しいアプローチを行うこと</span><br />ただ痛む部分を揉むだけではなく、体全体の構造を理解し、適切にケアを行うことが重要です。</li>
              <li className="my-2"><span className="marker">再発を防ぐための体づくり</span><br />痛みが解消した後も、その状態を維持するためのセルフケア方法やライフスタイル改善が必要です。</li>
            </ul>
          </div>
          <p className="my-5">
            しかし、この条件をすべて満たすのは非常に難しく、一般的な整体院やマッサージ店では対応しきれません。</p>
          <p className="mb-10">「どうすればこの条件を満たせるのか&#x2753;」</p>

          <p className="mb-10 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><PenLogo className="inline-block mr-1.5 -mt-1.5 width='10px' height='10px'" /><br /><span className="marker">ぺんぎん堂にお任せください</span></p>


          <p className="my-3">ぺんぎん堂では、解剖学と生理学を熟知した専門施術者が、一人ひとりの体の状態を徹底的に分析。痛みの原因を根本から特定します。</p>
          <div id="profile" />
          <div className="text-sm text-left bg-c-main my-0"><h2 className="mt-10 mb-3  p-1 text-center text-c-base">プロフィール</h2></div>


                    <p>こんにちは。ぺんぎん堂の橋本智子です。</p>
          
          
                    <div className="overflow-hidden">
                      <StaticImage
                        src="../images/seitai-session1.jpg"
                        alt="整体・オステオパシー施術の様子"
                        placeholder="blurred"
                        layout="constrained"
                        width={400}
                        className="lg:float-left mr-5 mt-3 mb-4 ml-2"
                      />
          
                   
          
                    
                    <p>”アメリカ式整体オステオパシー” という方法で体の不調を和らげるお手伝いをしております。</p>
                    <p>「どうして ”オステオパシー” ？」<br />少しだけ私の話をさせてください。</p>
                    <hr className="my-8 border-c-main" />
                    <p>元々、金融機関でITエンジニアをしていたのですが、自分の体や家族の体の不調から整体に興味を持ちました。</p>
                    <p>当初は、体の流れを読む、という整体を学んだりもしました。エンジニア思考が抜けきらないのか、私の性格か、</p><ul className="list-disc ml-6"><li>こうやればうまくいくことが多い</li><li>「なぜ？」は考えずとにかく実践！</li></ul><p>という整体には、なにか納得がいきませんでした。</p><p>本を読んだり、整体を受けてみたり試行錯誤しました。</p>
                    <p className="my-6 mx-3"><span className="c-marker text-lg">そんな私が行きついたのが、<span className="sm:hidden"><br /></span>アメリカ式整体<span className="lg:hidden"><br /></span>&nbsp;”オステオパシー”&nbsp;です。</span></p>
                    </div>
                    <p>触診・動き・姿勢などをみて、解剖生理学とオステオパシーの哲学を元に分析して施術する方法です。</p>
                    <p>「なぜ？」「何が原因？」がわかると、</p><ul className="list-disc ml-6"><li>整体でできること</li><li>日常生活で気を付けること</li><li>どうやって姿勢を保って予防するか</li></ul><p>などが、見えてきます。</p>


          <p>さらに、<span className="marker">アメリカ式整体オステオパシー独自の内臓アプローチや筋・筋膜リリース技術を組み合わせる</span>ことで、一般的な整体やマッサージでは得られない効果を実現します。</p>



          <p>
            その場しのぎではなく、根本改善を目指すので、動くたびの痛みから解放されます。<br />
            セルフケアの方法まで指導するので、痛みを繰り返しません。</p>

          <p>股関節の痛みで諦めていた</p>
            <ul className="list-none"><li>「動きやすい体」</li>
            <li>「おしゃれな生活」</li>
            <li>「充実した仕事とプライベート」</li></ul>
            <p>を、ぺんぎん堂の施術が叶えます。</p><p>試行錯誤はもう必要ありません。 </p>

          <p className="mt-10 mb-5 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><span className="marker">なぜそんなに効果的&#x2753;</span></p>


          <div className="bg-emerald-50 px-2 pb-4 rounded-xl">
            <ul className="list-decimal bg-emerald-50 py-3">
              <li className="my-2"><span className="marker">解剖学に基づいた徹底的な原因特定</span><br />

                痛みがどこから来ているのかを、筋肉、筋膜、骨格、内臓など多角的な視点で徹底的に分析。一般的な「痛い場所だけを施術する」整体とは一線を画します。痛みの根本原因を見つけるから、長年の悩みが解消します。
              </li>
              <li className="my-2"><span className="marker">内臓アプローチで体全体をケア</span><br />
                股関節の痛みを引き起こしている可能性のある、内臓を支える靱帯や”膜”の緊張も解放し、全身のバランスを整えます。<br />体全体が軽くなり、自己治癒力もアップ。
              </li>
              <li className="my-2"><span className="marker">女性施術者による安心の提供</span><br />

                女性ならではの体の悩みや不安、デリケートな症状でも気軽に相談できる環境です。緊張せず、リラックスして施術を受けていただけるので、効果もアップ。
              </li>
              <li className="my-2"><span className="marker">再発予防のためのセルフケア指導</span><br />

                施術だけでなく、再発を防ぐためのセルフケアや日常の体の使い方まで丁寧にアドバイスします。健康的な生活を維持できるようになります。</li>

            </ul>

            {/*
            <div className="flex flex-row">
              <div className="m-0.5">
                <StaticImage
                  src="../images/bone.jpg"
                  alt="脊柱の歪み"
                  placeholder="tracedSVG"
                  layout="constrained"
                  width={250}
                  className=""
                />
              </div>

              <div className="m-0.5">
                <StaticImage
                  src="../images/muscle.jpg"
                  alt="疲れた筋肉"
                  placeholder="tracedSVG"
                  layout="constrained"
                  width={250}
                  className=""
                />
              </div>
            </div>
            */}
          </div>

          <hr className="my-10" />
          <div id="voice" />
          <VoiceShortHipJ isShowButton={false} />
          <hr className="my-4" />



          <div className="overflow-hidden">

            <p className="my-5">ぺんぎん堂では、<span className="marker">アメリカの整体・オステオパシーという方法</span>で、全身調整します。 </p>
            <p>解剖学と生理学に基づいたアプローチで、股関節痛の根本原因を特定し、短期間で改善を目指します。</p>

            <ul className="list-decimal bg-emerald-50 py-3 mx-3">
              <li className="my-2"><span className="marker">原因の徹底分析</span><br />

                股関節痛の原因となる筋肉、骨格、内臓、筋膜を徹底的に分析し、個別の施術計画を作成。
              </li>
              <li className="my-2"><span className="marker">根本アプローチ</span><br />
                オステオパシー独自のテクニックで筋肉にアプローチ、筋膜リリース、内臓アプローチ、骨格調整を組み合わせ、体全体を整えます。
              </li>
              <li className="my-2"><span className="marker">セルフケア</span><br />
                再発予防のため、日常生活でできる簡単なケアをお伝えします。
              </li>


            </ul>
          </div>



          <div className="text-sm text-left bg-c-main mt-14 m-0"><h2 className="mt-14 mb-8  p-1 text-center text-c-base">施術の流れ・コース</h2></div>

          <div className="text-left md:mx-4 mt-8">
            <p>施術コースは2種類、</p><ul className="list-decimal ml-6"><li>初めての方向け</li><li>2回目以降のオステオパシーコース</li></ul><p>です。</p>
            <hr className="my-5" />


<div className="overflow-hidden">
  <StaticImage
    src="../images/seitai-session2.jpg"
    alt="オステオパシー施術の検査"
    placeholder="dominant"
    layout="constrained"
    width={400}
    className="mr-5 mt-3 mb-4 ml-2"
  />

</div>

<hr className="my-8" />


            <p className="mt-8"><span className="c-marker">【施術の流れ】</span></p>




            <div className="rounded border-2 border-c-main  md:mx-4 my-3">
              <p className="bg-c-main text-c-base pl-2">チェックシートに記入（初回のみ）</p><p>お体のお悩みなどをチェックシートに記入していただきます。わかる範囲で大丈夫です。</p><p> 初回5分</p></div>

            <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

            <div className="rounded border-2 border-c-main  md:mx-4 my-2">

              <p className="bg-c-main text-c-base pl-2">お着替え</p><p>スカートやタイトなパンツでいらしたかたは、ゆったりした服装に着替えていただきます。こちらにもお着替えの用意がございます。</p><p>3分</p></div>

            <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

            <div className="rounded border-2 border-c-main  md:mx-4 my-2">

              <p className="bg-c-main text-c-base pl-2">お話を伺います。</p><p>どこが痛いのか、どんな時に痛いのかなどをお伺いします。気になることはご遠慮なくお話してくださいね。</p><p> 初回10~15分、2回目以降5~10分</p></div>

            <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

            <div className="rounded border-2 border-c-main  md:mx-4 my-2">

              <p className="bg-c-main text-c-base pl-2">体の検査</p><p>どこが悪いのかを細かく検査します。細かくチェックすることで、お体の状態を把握して施術する方針を決めていきます。</p><p> 初回10~15分、2回目以降5分~10分</p></div>

            <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

            <div className="rounded border-2 border-c-main  md:mx-4 my-2">

              <p className="bg-c-main text-c-base pl-2">施術</p><p>検査した結果をもとに、施術します。軽く揺らしたり、力をつかわず筋肉をゆるめたりするので、リラックスして受けていただけますよ。</p><p> 40分~45分</p></div>

            <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>



            <div className="rounded border-2 border-c-main  md:mx-4 my-2">

              <p className="bg-c-main text-c-base pl-2">お体の説明とアドバイス</p><p>検査結果はどうだったのか、なにを施術したかをお話しします。日常生活で気を付ける点、座り方など姿勢のアドバイスもします。気になることはご遠慮なく聞いてくださいね。</p>
              <p>初回10~15分、2回目以降5分</p></div>
              <p className="mt-7">初回：約90分<br />
              2回目以降：約60分</p>
            <hr className="mt-10  bg-c-sub border-c-sub " />
            <div id="course" />
            <p className="mt-4 mb-4"><span className="c-marker">【コースと料金】</span></p>
            <p>コースはシンプル、初回と2回目以降の２つです。</p>
            <p className="my-1 font-semibold">初回オステオパシーコース</p>
            <p className="mt-1 mb-3 ml-10">施術料：8,800円<br />時間：約90分</p>
            <p className="my-1 font-semibold">2回目以降オステオパシーコース</p>
            <p className="mt-1 mb-3 ml-10">施術料：6,600円<br />時間：約60分</p>


          </div>
          <hr className="mt-10  bg-c-sub border-c-sub " />
          <div id="open" />
          <InfoNishiogiShort />
       

          <hr className="my-10" />


          <Cta link="/lp-holistic#form" idForGtm="cta-view" />
          <hr className="my-10" />

          <p className="marker">股関節の違和感・痛みを放置すると…</p>

          <p>

            「少しの痛みだから」と放置しておくと、体のバランスが崩れ、股関節だけでなく膝や腰、さらには全身に痛みが広がる可能性があります。</p>
          <p>痛みが原因で外出が億劫になり、友人との時間を諦める生活が続くかもしれません。</p>
          <p>そんな日々が続くと、「あの時、ちゃんとケアしていれば…」と後悔する日が来る可能性もあります。</p>


          <p className="text-center my-5"><PenLogo className="inline-block width='10px' height='10px'" /></p>

          <p>股関節の痛みを抱えながら、「このままでいいのかな…」と悩み続ける日々を終わりにしませんか？</p><p>ぺんぎん堂の施術で、あなたの体を軽くし、毎日をもっと楽しく変えましょう&#x1f604;</p>

          <hr className="my-10" />

          <InfoNishiogi />
          <hr className="my-10" />
          <div id="recommend" />
          <Recommend />





          <Cta link="/lp-holistic#form" isAnchor={true} />




          <div id="form" className="mb-6 pt-4 md:pt-8 px-3 py-6 bg-yellow-100">

            <HookFormsLp formName="lp-holistic" />
          </div>


        </div>
      </section>
    </LayoutLp>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
` 