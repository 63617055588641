import React, { useState, useEffect } from 'react'

import PenLogo from "../static/svg/pen-logo-text.inline.svg";
import ModalComponent from './modalComponent';

import CloseSharp from "../static/svg/icon-close.inline.svg";

const KasaiInfoBanner = (props) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 3000);
  }, []);

  const handleOpenModal = () => {
    setShow(true)
  }

  const handleCloseModal = () => {
    setShow(false)
    if( props.urlpath )
    { window.history.replaceState('','',props.urlpath) }
  }


  return (

    <>
      {show ?

        <ModalComponent
          isOpen={show}
          onClose={handleCloseModal}
          padding={0}
          buttonPosition={"right"}>

          <div className="bg-c-base p-5 border-2 border-c-main relative text-lg text-center" >

            <PenLogo className="ml-2 inline-block mr-1 mb-3" width='120px' height='50px' />
            <p>ホームページにお越しいただきありがとうございます&#x1f600;<br /><br /></p><p>葛西にご来院いただいていたお客様は、初診料はかかりませんので、お近くにお越しの際は是非&#x1f609;<br /><br /></p>
            <p>また、葛西のお客様からのご紹介の場合も、初診料はいただいておりません。ご予約時にご紹介の旨をお知らせいただきますよう、お伝えいただけると幸いです。&#x1f647;<br /><br /></p>
            <p>他にご不明な点がありましたら、お問い合わせフォームかお電話にてお知らせください。</p>
            <div className="mt-6">
          <button type="button" aria-label="Close" onClick={() => handleCloseModal()}
            className="border border-c-sub  font-bold bg-c-sub text-c-base hover-hover:hover:bg-c-main active:bg-c-main bold py-1 pl-0.5 pr-2 rounded align-middle">
            <CloseSharp className="w-6 h-6 inline-block  align-middle pb-0.5" />
            閉じる</button>
        </div>
          </div>
        </ModalComponent>

        : <></>}

    </>

  )

}

export default KasaiInfoBanner