import React from 'react'
import {  graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ExternalLink from "../static/svg/icon-external-link.inline.svg";



import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";
import NishiogiMap from "../components/nishiogiMap";



function Access({data, pageContext, location}) {

  const { breadcrumb: { crumbs } } = pageContext;
  
  const {pathname} = location;
  const pagetitle = "アクセス";
  const description = "西荻窪駅徒歩５分の整体院・ぺんぎん堂のご案内。杉並区で、肩こり・首痛・頭痛・腰痛などでお困りのかたへ。";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";


    return (
      <div>

        <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}  >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />

          <div>
            <h1 className="heading-b">アクセス</h1>
            <p className="mt-4">JR中央線・総武線 西荻窪駅南口より、徒歩5分です。</p>
            <p className="mb-6 underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
              <a href="https://www.google.com/maps/place/%E8%BA%AB%E4%BD%93%E3%81%AE%E8%AA%BF%E6%95%B4+%E3%81%BA%E3%82%93%E3%81%8E%E3%82%93%E5%A0%82%E3%82%AA%E3%82%B9%E3%83%86%E3%82%AA%E3%83%91%E3%82%B7%E3%83%BC/@35.7008897,139.6001098,16z/data=!4m2!3m1!1s0x6018efb916a1ec21:0x130ac75b617c9130" target="_blank" rel="noreferrer" >GoogleMapはこちら
                <ExternalLink className="inline  w-4 h-4 align-text-bottom pb-0.5" />
              </a></p>

            <p className="mt-3"><span className="font-semibold">住所：</span>〒{data.site.siteMetadata.postalcode} &nbsp; {data.site.siteMetadata.address}<br />西荻オステオパシーセンター内</p>



            <p>路線バスでお越しの方も、西荻窪駅よりお越しください。</p>
            <ul className="list-disc pl-6">

              <li>久我山方面から：<a href="https://www.city.suginami.tokyo.jp/_res/projects/default_project/_page_/001/005/011/kaedejikoku.pdf" target="_blank" rel="noreferrer" className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">すぎ丸 かえで路線    <ExternalLink className="inline w-4 h-4 align-text-bottom pb-0.5" />
              </a> </li>
              <li>大泉学園、上石神井、吉祥寺、井荻方面から：西荻窪駅までの路線バスがあります。関東バスのページで検索してください。<a href="https://www.kanto-bus.co.jp/regular/" target="_blank" rel="noreferrer" className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">関東バス<ExternalLink className="inline w-4 h-4 align-text-bottom pb-0.5" /></a></li>

            </ul>


            <div className="mb-6 responsive-map">
<StaticImage
    src="../images/NishiogiMap.png"
    alt="西荻窪駅南口からぺんぎん堂西荻窪院までの地図"
    placeholder="tracedSVG"
    layout="constrained"
    width={534}
 />
  </div>
            
            <NishiogiMap lang='ja' />

</div>
        </LayoutSub>
      </div>

      
    )
}

export default Access;

export const query = graphql`
query {
  site {
  siteMetadata {
  siteUrl
  address
  tel
  postalcode
}
}
}
`