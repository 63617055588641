import React from 'react'
import ExLink from "../../components/exLink";

import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import SEO from "../../components/seo";
import LayoutSub from "../../components/layoutSub";

import Call from "../../static/svg/icon-call.inline.svg";
import Mail from "../../static/svg/icon-mail.inline.svg";




function Kasai({data, pageContext, location}) {
  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "Kasai: Kasai Osteopathy Room";
  const description = "Kasai: Are you looking for relief from pain or discomfort?  We're here to help you at Penguindou Kasai branch.";

    return (
      <div>

        <LayoutSub  crumbs={crumbs} pathname={pathname}  lang='en' >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
            lang='en'
          />

          <div>
            <h1 className="heading-b">Kasai: Kasai Osteopathy Room</h1>

            <p>We&#39;re closed for business in Kasai.</p><p>If you are interested in receiving treatment in Kasai from other osteopaths, please contact <ExLink href="http://k-oste-noha.moon.bindcloud.jp/">Kasai Osteopathy Room</ExLink>  directly, or you&#39;re very welcome to contact us at Nishiogikubo.&#x1f600;</p>

            <div className="sm:pointer-events-none">
            <a href={`tel:${data.site.siteMetadata.tel}`.replace(/\s+/g, "")}>
            <p className="text-2xl text-darkteal font-semibold underline sm:no-underline my-3 ">
            <Call className="w-6 h-6 inline pb-0.5 ml-1" />
            {data.site.siteMetadata.tel}
            </p>
            </a>
            </div>

            <AnchorLink to="#form" >
            <button type='button' className="border border-emerald-600 bg-emerald-500 text-white hover-hover:hover:bg-emerald-600 active:bg-emerald-600 text-center my-4 py-2 px-4 rounded font-semibold shadow">

            <Mail className="h-6 w-6 inline-block mr-2 align-bottom" />
            Send Us Message

            </button>
          </AnchorLink>
                 
        </div>


        </LayoutSub>
      </div>

      
    )
}

export default Kasai;

export const query = graphql`
query {
  site {
  siteMetadata {
  tel
}
}
}
`