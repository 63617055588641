import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import Arrow from "../static/svg/arrow.inline.svg";

import LayoutLp from "../components/layoutLp";
import SEO from "../components/seo";
import VoiceShortHipJ from "../components/voiceShortHipJ";
import HookFormsLp from "../components/hookFormsLp";

import InfoNishiogi from "../components/infoNishiogi";
import InfoNishiogiShort from "../components/infoNishiogiShort";

import Cta from "../components/cta";
import Recommend from "../components/recommend";

import PenLogo from "../static/svg/pen-logo.inline.svg";
import LpSciaticaHeroImg from "../components/lpSciaticaHeroImg";



function LpSciatica({ location }) {
    const { pathname } = location;
    const pathTopNoSlush = pathname.endsWith('/') ? pathname.substr(0, pathname.length - 1) : pathname;

    const pagetitle = "西荻窪の整体≪ぺんぎん堂≫";
    const description = "西荻窪から徒歩3分のアメリカ式整体・オステオパシ―専門院。坐骨神経痛でお困りの方へ";

    return (
        <LayoutLp pathTop={pathname}>
            <SEO
                pageurl={pathname}
                pagetitle={pagetitle}
                description={description}
                isLp
                isNoindex
            />
            <LpSciaticaHeroImg />


            <section className="text-center mx-5">
                <p className="mt-10 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><span className="marker">あなたの未来はどちらですか？</span></p>

                <p className="mt-5">

                    ある人は、適切な方法を見つけられず、痛みと共に日常を過ごしています。<br />何度もマッサージに通い、一時的に楽になっても数日で元通り。ストレッチや健康グッズも効果を感じられず、さらに迷いや不安が募っていきます。 </p>
                <p className="mt-5">

                    一方で、正しいアプローチを選んだ人は、わずか数週間で痛みから解放されました。<br />仕事にも集中でき、趣味や家族との時間を楽しむ余裕が生まれ、生活全体が変わったのです。</p>
                <p className="mt-5">
                    結果を分けたのは、<span className='marker'>「何を選び、どう行動したか」の違いだけ。</span>あなたなら、どちらを選びますか？


                </p>



                <div className="text-left mx-1 md:mx-4">

                    <p className="mt-10 mb-5 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><span className="marker">痛みから解放されるために必要なこと</span></p>


                    <p className="sm:mt-3">坐骨神経痛や足のしびれを解決するために、マッサージや湿布、痛み止めに頼る人は多いですよね。<br />でも、本当に痛みを解消し、自由な生活を取り戻すためには、痛みの根本原因にアプローチする専門的な方法が必要。それは、</p>
                    <p><span className="marker">『身体全体のバランスを整え、自己治癒力を引き出すこと』</span></p>
                    <p>

                        一般的な湿布やストレッチ、薬では、痛みを一時的に和らげることはできても、根本的な解決にはなりません。<br />整骨院やマッサージも、腰や骨盤などの痛みを感じる部分だけにフォーカスしがち。</p><p>「全身のバランスを整える」という視点が抜けていることが多いのです。</p>

                    <p className="mb-10">「じゃあ、どうすればいいの&#x2753;」</p>


                    <p className="mb-10 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><PenLogo className="inline-block mr-1.5 -mt-1.5 width='10px' height='10px'" /><br /><span className="marker">ぺんぎん堂にお任せください</span></p>
                    <p className="my-3">

                        今まで解決できなかった痛みの原因に、確実にアプローチする方法があります。</p><p>それは、</p>
                    <ul className="list-none"><li>「解剖学に基づく専門施術」</li>
                        <li>「身体全体のケア」</li></ul>
                    <p>を組み合わせた独自のアプローチです。</p>
                    <p>
                        内臓や頭蓋まで含む全身のバランスを整え、自己治癒力を最大限に引き出すことで、あなたの身体を根本から改善します。</p>


                    <div id="profile" />
                    <div className="text-sm text-left bg-c-main my-0"><h2 className="mt-10 mb-3  p-1 text-center text-c-base">プロフィール</h2></div>


                    <p>こんにちは。ぺんぎん堂の橋本智子です。</p>

                    <div className="overflow-hidden">
                        <StaticImage
                            src="../images/seitai-session1.jpg"
                            alt="整体・オステオパシー施術の様子"
                            placeholder="blurred"
                            layout="constrained"
                            width={400}
                            className="lg:float-left mr-5 mt-3 mb-4 ml-2"
                        />

                        <p>”アメリカ式整体オステオパシー” という方法で体の不調を和らげるお手伝いをしております。</p>
                        <p>「どうして ”オステオパシー” ？」<br />少しだけ私の話をさせてください。</p>
                        <hr className="my-8 border-c-main" />
                        <p>元々、金融機関でITエンジニアをしていたのですが、自分の体や家族の体の不調から整体に興味を持ちました。</p>
                        <p>当初は、体の流れを読む、という整体を学んだりもしました。エンジニア思考が抜けきらないのか、私の性格か、</p><ul className="list-disc ml-6"><li>こうやればうまくいくことが多い</li><li>「なぜ？」は考えずとにかく実践！</li></ul><p>という整体には、なにか納得がいきませんでした。</p><p>本を読んだり、整体を受けてみたり試行錯誤しました。</p>
                        <p className="my-6 mx-3"><span className="c-marker text-lg">そんな私が行きついたのが、<span className="sm:hidden"><br /></span>アメリカ式整体<span className="lg:hidden"><br /></span>&nbsp;”オステオパシー”&nbsp;です。</span></p>
                    </div>
                    <p>触診・動き・姿勢などをみて、解剖生理学とオステオパシーの哲学を元に分析して施術する方法です。</p>
                    <p>「なぜ？」「何が原因？」がわかると、</p>
                    <ul className="list-disc ml-6">
                        <li>整体でできること</li>
                        <li>日常生活で気を付けること</li>
                        <li>どうやって姿勢を保って予防するか</li>
                    </ul>
                    <p>などが、見えてきます。</p>

                    <p>さらに、<span className="marker">アメリカ式整体オステオパシー独自の内臓アプローチや筋・筋膜リリース技術を組み合わせる</span>ことで、一般的な整体やマッサージでは得られない効果を実現します。</p>

                    <p>
                        その場しのぎではなく、根本改善を目指すので、動くたびの痛みから解放されます。<br />
                        セルフケアの方法まで指導するので、痛みを繰り返しません。</p>

                    <p>腰・お尻や足の痛みで諦めていた</p>
                    <ul className="list-disc ml-6"><li>自由に動ける体</li>
                        <li>痛みを気にせず楽しめる充実した時間</li>
                        <li>「また痛みが戻るかも」という不安からの解放</li></ul>
                    <p>を、ぺんぎん堂の施術が叶えます。</p><p>試行錯誤はもう必要ありません。 </p>


                    <p className="mt-10 mb-5 text-center font-bold text-2xl sm:text-3xl md:text-rxl"><span className="marker">なぜそんなに効果的&#x2753;</span></p>

                    <div className="bg-emerald-50 px-2 pb-4 rounded-xl">
                        <ul className="list-decimal bg-emerald-50 py-3">
                            <li className="my-2"><span className="marker">解剖学に基づく専門的なアプローチ</span><br />

                                体全体を「一つのつながり」として捉え、筋肉、骨格、内臓、そして頭蓋の状態を徹底的に分析。これにより、症状の根本原因を見つけ出します。

                            </li>
                            <li className="my-2"><span className="marker">内臓や頭蓋骨へのアプローチ</span><br />
                                内臓と筋骨格をつなぐ膜の緊張が、不調につながることも。<br />特に、骨盤周りや足に不調の出ている場合は<span className="marker">内臓をとりまく膜に原因があることも多々あります。</span><br />
                                オステオパシーの手技で、内臓や頭蓋骨へもアプローチします。
                            </li>
                            <li className="my-2"><span className="marker">女性施術者による安心の提供</span><br />

                                女性ならではの体の悩みや不安、デリケートな症状でも気軽に相談できる環境です。緊張せず、リラックスして施術を受けていただけるので、効果もアップ。
                            </li>
                            <li className="my-2"><span className="marker">持続する改善効果</span><br />

                                一時的な痛みの緩和ではなく、全身のバランスを整えることで、体調の安定を実現。施術後も「もっと元気に動ける日々」が続きます。

                            </li>

                        </ul>

                    </div>

                    <hr className="my-10" />
                    <div id="voice" />
                    <VoiceShortHipJ isShowButton={false} />
                    <hr className="my-4" />

                    <div className="overflow-hidden">

                        <p className="my-5">ぺんぎん堂では、<span className="marker">アメリカの整体・オステオパシーという方法</span>で、全身調整します。 </p>
                        <p>解剖学と生理学に基づいたアプローチで、不調の根本原因を特定し、短期間で改善を目指します。</p>

                        <ul className="list-decimal bg-emerald-50 py-3 mx-3">
                            <li className="my-2"><span className="marker">根本原因の特定</span><br />
                                肩こりや頭痛などの不調の原因となる、骨格、頭蓋骨、内臓、筋膜・筋肉を徹底的に分析し、個別の施術計画を作成。
                            </li>
                            <li className="my-2"><span className="marker">独自技術による根本アプローチ</span><br />
                                オステオパシー独自のテクニックで、筋膜リリース、内臓アプローチ、頭蓋骨調整、骨格調整を組み合わせ、体全体を整えます。
                            </li>
                            <li className="my-2"><span className="marker">女性施術者による細やかなケア</span><br />
                                女性特有の体の悩みや不安にも寄り添い、リラックスできる空間を提供します。
                            </li>

                        </ul>
                    </div>


                    <div className="text-sm text-left bg-c-main mt-14 m-0"><h2 className="mt-14 mb-8  p-1 text-center text-c-base">施術の流れ・コース</h2></div>

                    <div className="text-left md:mx-4 mt-8">
                        <p>施術コースは2種類、</p><ul className="list-decimal ml-6"><li>初めての方向け</li><li>2回目以降のオステオパシーコース</li></ul><p>です。</p>
                        <hr className="my-5" />

                        <div className="overflow-hidden">
                            <StaticImage
                                src="../images/seitai-session2.jpg"
                                alt="オステオパシー施術の検査"
                                placeholder="dominant"
                                layout="constrained"
                                width={400}
                                className="mr-5 mt-3 mb-4 ml-2"
                            />

                        </div>

                        <hr className="my-8" />

                        <p className="mt-8"><span className="c-marker">【施術の流れ】</span></p>

                        <div className="rounded border-2 border-c-main  md:mx-4 my-3">
                            <p className="bg-c-main text-c-base pl-2">チェックシートに記入（初回のみ）</p><p>お体のお悩みなどをチェックシートに記入していただきます。わかる範囲で大丈夫です。</p><p> 初回5分</p></div>

                        <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

                        <div className="rounded border-2 border-c-main  md:mx-4 my-2">

                            <p className="bg-c-main text-c-base pl-2">お着替え</p><p>スカートやタイトなパンツでいらしたかたは、ゆったりした服装に着替えていただきます。こちらにもお着替えの用意がございます。</p><p>3分</p></div>

                        <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

                        <div className="rounded border-2 border-c-main  md:mx-4 my-2">

                            <p className="bg-c-main text-c-base pl-2">お話を伺います。</p><p>どこが痛いのか、どんな時に痛いのかなどをお伺いします。気になることはご遠慮なくお話してくださいね。</p><p> 初回10~15分、2回目以降5~10分</p></div>

                        <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

                        <div className="rounded border-2 border-c-main  md:mx-4 my-2">

                            <p className="bg-c-main text-c-base pl-2">体の検査</p><p>どこが悪いのかを細かく検査します。細かくチェックすることで、お体の状態を把握して施術する方針を決めていきます。</p><p> 初回10~15分、2回目以降5分~10分</p></div>

                        <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

                        <div className="rounded border-2 border-c-main  md:mx-4 my-2">

                            <p className="bg-c-main text-c-base pl-2">施術</p><p>検査した結果をもとに、施術します。軽く揺らしたり、力をつかわず筋肉をゆるめたりするので、リラックスして受けていただけますよ。</p><p> 40分~45分</p></div>

                        <div className="text-center"><Arrow className=" inline-block w-8 h-8 lg:w-8 lg:h-8 xl:w-10 xl:h-10" /></div>

                        <div className="rounded border-2 border-c-main  md:mx-4 my-2">

                            <p className="bg-c-main text-c-base pl-2">お体の説明とアドバイス</p><p>検査結果はどうだったのか、なにを施術したかをお話しします。日常生活で気を付ける点、座り方など姿勢のアドバイスもします。気になることはご遠慮なく聞いてくださいね。</p>
                            <p>初回10~15分、2回目以降5分</p></div>
                        <p className="mt-7">初回：約90分<br />
                            2回目以降：約60分</p>
                        <hr className="mt-10  bg-c-sub border-c-sub " />
                        <div id="course" />
                        <p className="mt-4 mb-4"><span className="c-marker">【コースと料金】</span></p>
                        <p>コースはシンプル、初回と2回目以降の２つです。</p>
                        <p className="my-1 font-semibold">初回オステオパシーコース</p>
                        <p className="mt-1 mb-3 ml-10">施術料：8,800円<br />時間：約90分</p>
                        <p className="my-1 font-semibold">2回目以降オステオパシーコース</p>
                        <p className="mt-1 mb-3 ml-10">施術料：6,600円<br />時間：約60分</p>


                    </div>
                    <hr className="mt-10  bg-c-sub border-c-sub " />
                    <div id="open" />
                    <InfoNishiogiShort />


                    <hr className="my-10" />


                    <Cta link={pathTopNoSlush.concat('#form')} idForGtm="cta-view" />
                    <hr className="my-10" />

                    <p><span className="marker">正しいケアを受けずに不調を放置すると…</span></p>
                    <p>
                        「今はなんとか我慢できる」という状態でも、放置すれば悪化するリスクがあります。</p>

                    <p>体調が悪いと、笑顔の余裕がなくなります。<br />外出が億劫になり、家族や友人との時間を諦める生活が続くかもしれません。</p>

                    <p>「忙しいから」「まだ我慢できるから」と先延ばしにするうちに、より深刻な不調に発展し、時間やコストがさらにかかる状況になる可能性も。</p>
                    <p>特に怖いのは、「痛みに慣れてしまう」ことです。痛みを我慢する生活が日常になってしまうと、気づかないうちに症状は着実に進行していきます。</p>

                    <p className="text-center my-5"><PenLogo className="inline-block width='10px' height='10px'" /></p>

                    <p>もっと快適で笑顔の多い毎日を送るために、今こそ行動を起こしませんか？</p><p>ぺんぎん堂の施術で、あなたの体を軽くし、毎日をもっと楽しく変えましょう&#x1f604;</p>

                    <hr className="my-10" />

                    <InfoNishiogi />
                    <hr className="my-10" />
                    <div id="recommend" />
                    <Recommend />

                    <Cta link={pathTopNoSlush.concat('#form')} isAnchor={true} />

                    <div id="form" className="mb-6 pt-4 md:pt-8 px-3 py-6 bg-yellow-100">

                        <HookFormsLp formName="lp-sciatica" pathTop={pathname} />
                    </div>

                </div>
            </section>
        </LayoutLp>
    );
}

export default LpSciatica;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
` 