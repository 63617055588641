import React from 'react'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

function VoiceShortAutoN () {

  return (
    <div className="">


      <h2 className="mt-0 mb-3  mx-0 p-1  text-c-base bg-c-main">お客様の声をご紹介</h2>

      <div className="mx-2 p-2 py-4 bg-c-base border-c-acc border-dotted border-2 ">

      <div className="overflow-hidden">
      <StaticImage
              src="../images/womanface1.png"
              alt="女性のお客様"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-2"
            />
       <p className="my-0.5">Jasmine様/38歳</p>
      </div>

        <div className="overflow-hidden">
            <p>
                体のきしみを感じにくくなった<br />
            </p>
         
          <p>
          注意いただいたポイントを気を付けていると生活しやすくなりました！ありがとうございます！！
            </p>

        </div>
        
      <hr className="topix-border" />


      <div className="overflow-hidden">
      <StaticImage
              src="../images/womanface2.png"
              alt="女性のお客様"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-2"
            />
        <p className="my-0.5">山畑 由紀江様/50代</p>
      </div>


      <div className="overflow-hidden">


        <p>施術の後、ベッドから立った時に、”体が軽い”とびっくりしました。</p>
        <p>した方がいいこと、しない方がいいことを教えてくれて日常生活に役立っています。</p>
      </div>
           
      <hr className="topix-border" />

        

        <div className="overflow-hidden">
        <StaticImage
              src="../images/womanface3.png"
              alt="女性のお客様"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-2"
            />

        <p className="my-0.5">M.N.様/50代</p>
      </div>

      <div className="overflow-hidden">


        <p>とても丁寧にみていただき、ひとつひとつ細やかに説明してもらったので安心して施術を受けることができました。</p>
      </div>


      
      
      <hr className="topix-border" />

      </div>
    </div>
  )
};

export default VoiceShortAutoN



