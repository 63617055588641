import React from "react"
import { useForm } from 'react-hook-form/dist/index.ie11'; // V6
import { navigate } from "gatsby"
import PropTypes from "prop-types";


import Mail from "../static/svg/icon-mail.inline.svg"
import MailSend from "../static/svg/icon-mailsend.inline.svg";

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export const HookFormLp = ({lang, formName, pathTop }) => {

  const parmPathTop = { pathTop: (pathTop) };
  const queryParams = new URLSearchParams(parmPathTop).toString();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  
 
  const onSubmit = (data,e) => {

    {/*
console.log("ログ")
    console.log(data.symptom.join('<br />'))
    console.log(JSON.stringify(data));
 console.log(`data:JSON.stringify(data), formName:${formName}, data.symptom:${data.symptom}`);
  */}
 
 

      const errMsg =  (lang === 'ja' ) 
      ? 'エラーが発生したため、お問い合わせを送れませんでした。申し訳ありませんが、お電話にてお問い合わせください。' 
      : 'Sorry, unexpected error occurs and we cannot receive your message.  Please contact us by phone.';

      const thanksPg = (lang === 'ja') ? '/lp-thanks': '/en/thanks';

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": formName, ...data })
      })
      .then(response => {

        if (!response.ok) {
          alert(errMsg);
        }
        else {
        navigate(`${thanksPg}?${queryParams}`,
                             { state: { ...data } },
                            );
        }
      })
      .catch(error => {
        alert(errMsg);
      });

        e.preventDefault();
    };

    const name =  (lang === 'ja' ) 
    ? {label:'お名前', errMsg:'お名前を入力してください'}
    : {label: 'Name', errMsg:'Please enter your name'};

    const email = (lang === 'ja')
    ? {label:'メールアドレス', 
       errMsg1:'メールアドレスを入力してください', 
       errMsg2:'正しいメールアドレスを入力してください'}
    : {label: 'E-mail', 
       errMsg1:'Please enter your email address', errMsg2:'Please enter your email address in format: yourname@example.com'};

    const tel =  (lang === 'ja' ) 
    ? {label:'お電話番号'}
    : {label: 'Phone'};

    const datetime = (lang === 'ja')
    ? {label:'ご希望日時（ご予約のかた）', 
       desc: '例：11月15日16時以降、11月20日または27日の10時 等',
       desc2: '※ご予定がわからないかたは、空欄で結構です。直近の候補日時をお知らせします。'}
    : {label:'Preferred date and time',
       desc: 'example: 15th Nov after 16:00, 20th Nov or 27th Nov at 10:00',
       desc2: ''};
   
    const symptom = (lang === 'ja')
    ? {label:'何にお悩みですか？', 
       neck:'首のコリ・痛み',
       shoulder:'肩のコリ・痛み',
       back:'背中のコリ・痛み',
       lowerBack:'腰のコリ・痛み',
       knee:'膝の痛み',
       fatigue:'疲れがとれない',
       other:'その他',
       desc:'お悩みの項目をチェックしてください（複数選択可）'}
    : {label: 'What is your problem?',neck:'neck pain', 
       shoulder:'shoulder pain',
       back:'back pain',
       lowerBack:'lower back pain',
       knee:'knee pain',
       fatigue:'fatigue',
       other:'other',
       desc:'Check the corresponding items'};

    const message = (lang === 'ja')
    ? {label:'ご相談内容・備考など',
       errMsg:'ご相談内容・備考などを入力してください'}
    : {label:'Message',
       errMsg:'Please enter your message'};

    const required = (lang === 'ja') ? '必須' : 'Required';
    const optional = (lang === 'ja') ? '任意' : 'Optional';




  return (
      <>
    { formName
        ? 
    // name属性とdata-netlify="true"が必要ここのデータをもとにFormが登録される。
    <form
      noValidate
      name={formName}
      data-netlify="true"
      data-netlify-honeypot="mobile"
      onSubmit={handleSubmit(onSubmit)}

    >
      <h2 className="heading-l mb-6">
      <Mail className="h-6 w-6 inline-block mr-2 mb-0.5" />
      { (lang === 'ja')  ? 'ご予約・ご相談フォーム'
      : 'Send Us Message' }
      </h2>

      { (lang === 'ja')  ? <p>
        お問い合わせは、24時間受け付けております。</p>
        :<p>We&apos;ll get back to you within one business day. </p>
      }
      { (lang === 'ja') ?
      <p className="mb-5"> 1～2日以内に回答いたしますが、お急ぎの場合、また1日経っても返信がない場合は、お手数ですがお電話にてお問い合わせください。</p>
      :<p className="mb-5"> If you have not heard from us within one business day, please get in touch with us by phone to be sure we have received your form. </p>
      }

      <label
        className="block mb-2 font-bold"
        htmlFor="name"
      >
        <p>{name.label}&nbsp;<span className="bg-required py-0.5 px-2 text-xs text-white">{required}</span></p>
        {errors.name && errors.name.type === "required" && (
          <p className="mb-3 font-normal text-iered ">{name.errMsg}</p>
        )}
        <input
          className="w-full mb-6 rounded-md border-gray-800"
          name="name"
          id="name"
          
          ref={register({ required: true })} 
          type="text"
          required
          inputMode="text"
        />
      </label>

      <label
        className="block mb-2  font-bold"
        htmlFor="email"
      >
        <p>{email.label}&nbsp;<span className="bg-required py-0.5 px-2 text-xs text-white">{required}</span></p>
        {errors.email && errors.email.type === "required" && (
              <p className="mb-3 font-normal text-iered ">{email.errMsg1}</p>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <p className="mb-3 font-normal text-iered ">{email.errMsg2}</p>
            )}
          <input
            className="w-full mb-6 rounded-md border-gray-800"
            name="email"
            id="email"
            type="email"
            inputMode="email"
            ref={register({ required: true, pattern:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}  />

 

      </label>

      <label
        className="block mb-2 font-bold"
        htmlFor="tel"
      >
        <p>{tel.label}&nbsp;<span className="bg-optional py-0.5 px-2 text-xs text-white">{optional}</span></p>
        <input
          className="w-full mb-6 rounded-md border-gray-800"
          name="tel"
          id="tel"
          
          ref={register}
          type="tel"
          inputMode="tel"
          />
      </label>



      <label
        className="block mb-2  font-bold "
        htmlFor="datetime"
      >
      <p className="mb-0">
        {datetime.label}</p>
      <p className="font-normal my-0">&nbsp;{datetime.desc}</p><p className="font-normal mt-0">&nbsp;{datetime.desc2}</p>
        <textarea className="w-full mb-6 rounded-md border-gray-800" name="datetime" id="datetime" rows="3" 
         ref={register} />
      </label>

{/*
      <fieldset >
      <legend><p className="block mb-0  font-bold ">{symptom.label}<span className="bg-optional py-0.5 px-2 text-xs text-white">{optional}</span></p>
      <p className="font-normal mt-0">&nbsp;{symptom.desc}</p></legend>

      <div className="w-full mb-6 rounded-md border-gray-600 border-1 p-2 block">

          <div className="mx-1 my-1">
            <label htmlFor="c-neck">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-neck"
              ref={register}
              type="checkbox"
              value={symptom.neck}
            />
            {symptom.neck}
          </label>
          </div>


          <div className="mx-1 my-1">
          <label htmlFor="c-shoulder">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-shoulder"
              ref={register}
              type="checkbox"
              value={symptom.shoulder}
            />
            {symptom.shoulder}
            </label>
          </div>

          <div className="m-1">
          <label htmlFor="c-back">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-back"
              ref={register}
              type="checkbox"
              value={symptom.back}
            />
            {symptom.back}
            </label>
          </div>

          <div className="m-1">
          <label htmlFor="c-lower-back">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-lower-back"
              ref={register}
              type="checkbox"
              value= {symptom.lowerBack}
            />
            {symptom.lowerBack}
            </label>
          </div>

          <div className="m-1">
          <label htmlFor="c-knee">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-knee"
              ref={register}
              type="checkbox"
              value={symptom.knee}
            />
            {symptom.knee}
            </label>
          </div>

          <div className="m-1">
          <label htmlFor="c-fatigue">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-fatigue"
              ref={register}
              type="checkbox"
              value={symptom.fatigue}
            />
            {symptom.fatigue}
            </label>
          </div>

          <div className="m-1">
          <label htmlFor="c-other">
            <input
              className="border-gray-800 mr-1"
              name="symptom"
              id="c-other"
              ref={register}
              type="checkbox"
              value= {symptom.other}
            />
            {symptom.other}
            </label>
          </div>
      
      </div>
      </fieldset>
      */}
     <input type="hidden" name="symtom" value={symptom.other} ref={register} />


      <label
        className="block mb-2  font-bold "
        htmlFor="message">
      <p>
        {message.label}
      </p>
      <textarea className="w-full mb-6 rounded-md border-gray-800" type="message" name="message" id="message" rows="7"
       ref={register}  />
      </label>

      <input type="hidden" name="mobile" value="" ref={register} />
      <input type="hidden" name="form-name" value="contact" />

      {errors.name && errors.name.type === "required" && (
          <p className="m-0 font-normal text-iered ">{name.errMsg}</p>
        )}

      {errors.email && errors.email.type === "required" && (<p className="m-0 font-normal text-iered ">{email.errMsg1}</p>)}
      {errors.email && errors.email.type === "pattern" && (<p className="m-0 font-normal text-iered ">{email.errMsg2}</p>)}
      {errors.message && errors.message.type === "required" && (
        <p className="m-0 font-normal text-iered ">{message.errMsg}</p>
      )}

 
<div className="text-center">
      <button type="submit" id="btn_send_lp" className="border bg-c-main text-c-base hover-hover:hover:bg-c-sub  active:bg-c-sub text-center my-4 py-2 px-4 rounded-full font-semibold shadow-lg shadow-c-acc">
      <MailSend className="fill-current height-auto inline-block mr-2 text-2xl align-bottom" />
      {  (lang === 'ja' ) ? '送信する': 'Send Message' }</button>
        </div>
    </form>
    : <p>Form name is not specified.</p>
      }
      </>
  );
};

HookFormLp.defaultProps = {
  lang: `ja`,
  pathTop: `/lp-holistic/`,
};

HookFormLp.propTypes = {
  lang: PropTypes.string,
  pathTop: PropTypes.string,
};

export default HookFormLp;