import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useStaticQuery,graphql,Link } from "gatsby";

import Mail from "../static/svg/icon-mail.inline.svg"
import Call from "../static/svg/icon-call.inline.svg"
import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import InfoCircle from "../static/svg/icon-information-circle-outline.inline.svg";

import LineLogo from "../static/svg/line.inline.svg";

function CtaBlog(  ) {
  const data = useStaticQuery(graphql`
  query ctaBlogQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  
  `);

  const  tel   = (data.site.siteMetadata.tel).replace(/\s+/g, "");

return(
  <>
      <p>
      西荻窪で営業しております。<br />
        <Link to='/nishiogi/' className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
          <InfoCircle className="w-6 h-6 inline pb-0.5" />
          ご案内
        </Link></p>

        <div className="sm:pointer-events-none">
        <p className="text-xl mb-0">       
      <a href={`tel:${tel}`}>

        <Call className="fill-current w-5 h-5 inline  mr-0.5" />
        &nbsp;{data.site.siteMetadata.tel}

      </a>
      </p>
      </div>
      <p className="my-0 text-sm sm:hidden">（タップで電話できます。）</p>
      <p className="mb-4 mt-1" >

        <AnchorLink to='/contact/#form' className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
          <Mail className="h-5 w-5 inline mr-1.5" />
          ご予約・お問い合わせフォーム
        </AnchorLink></p>

      <p className="text-sm mb-0">LINEからもお問い合わせできます。</p>
      <p>
      <a href='https://lin.ee/7RK303'>
        <span className="inline-block">
          <LineLogo
            height="36"
            width="116" /></span>
      </a>
      </p>
      </>);
}

export default CtaBlog;