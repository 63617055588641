import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb  } from 'gatsby-plugin-breadcrumb'
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Globe from "../static/svg/icon-globe.inline.svg";
import Home from "../static/svg/icon-home.inline.svg";
import CaretForward from "../static/svg/icon-caret-forward.inline.svg";
import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import BackArrow from "../static/svg/icon-back-arrow.inline.svg"

import Menu from "./menu"
import Footer from "./footer"
import FooterEn from "./footerEn"
import SideMenu from "./sideMenu"
import SideMenuE from "./sideMenuE"
import HeroImg from "./heroImg"
import useBilingualUrl from "../hooks/useBilingualUrl";

import JoyRide from "../static/svg/joyride.inline.svg";
import Workout from "../static/svg/workout.inline.svg";

function LayoutBlogTop ({ children, lang, isBlog, crumbs, pathname, isNotFound, pathTop }){

  const crumbsNew = [];

  function createdBread(item) {
    const newlast = (item.pathname.match(/.*\/$/))
      ? item.pathname
      : item.pathname.concat('/')
      ;
      if ( ! ( lang==='en' && item.pathname==='/' ) ) {
        if (item.pathname==='/' && pathTop )
        {
          crumbsNew.push({ pathname: pathTop, crumbLabel: item.crumbLabel });

        }
        else {
    crumbsNew.push({ pathname: newlast, crumbLabel: item.crumbLabel });
        }
      }
  }

  if ( crumbs ) { crumbs.forEach(createdBread) };

  const bilingual = useBilingualUrl( pathname );
  const {isBilingual} = bilingual;

  const pathToTopG = ( lang === 'ja') ? '/' : '/en/';
  const pathToTop =  pathTop || pathToTopG;

  {/* console.log(`patToTop:${pathToTop}, pathTop:${pathTop}, pathToTopG:${pathToTopG}`); */}

  const pathToTopDesc = ( lang === 'ja') ? 'トップページへ戻る' : 'Back To Home';
  
  return (
    <div className="w-full flex flex-col min-h-screen font-body text-gray-900 tracking-wide leading-8 bg-c-base select-none">
      <main
        className="container flex-1 xs:px-4 w-full max-w-screen-2xl mx-auto md:px-8 ">
        { pathTop ? <Menu lang={lang} pathTop={pathTop} />
                  :
        <Menu lang={lang} /> }
        {  isBilingual
                    ?  
                    <div className="bg-yellow-50 text-right pt-0.5 pb-1 px-2 text-0">
                    <Link to={(lang === 'ja') ? bilingual.pageurlE : bilingual.pageurlJ }
                  
                   className="text-xs xs:text-base" >
                      <CaretForward className="h-4 w-4 inline-block" />
                      &nbsp;
                      <Globe className="h-4 w-4 inline-block mr-0.5" />
                      <span className="underline">
                        {(lang === 'ja') ? "English" : "日本語"}
                      </span>
                    </Link>
                  </div>
                  : "" 
        }

        <div className='text-sm sm:text-base py-1'>

          { ( crumbs ) ?
          <Breadcrumb crumbs={crumbsNew} crumbSeparator="&nbsp;&nbsp;/&nbsp;"
          />
          :<span className="py-3" /> }
        </div>

<div className=" py-5">
        <div className="mx-10 max-w-4xl m-auto relative sm:mt-2">

            <JoyRide className="mt-7 h-20 w-20 z-10 sm:h-28 sm:w-28  sm:mt-1.5 lg:w-32 lg:mt-2 lg:ml-12  absolute" />
 
           <Workout className="mt-6  h-14 w-14 z-10 sm:h-28 sm:w-28 sm:mb-1.5 lg:w-32 lg:mb-2 lg:mr-12   sm:block absolute right-0"/>

          <div className="m-auto flex flex-col justify-center text-center hidden-ie  z-20 relative text-c-acc">
          <p className="mt-0 text-sm sm:text-base lg:text-lg">アメリカ式整体オステオパシーの施術師による</p><p className="text-xl xs:text-2xl sm:text-3xl lg:text-4xl">身体のコラム</p>
          
          <h1 className="mt-2 font-bold text-xl xs:text-2xl  sm:text-3xl lg:text-4xl text-right xxs:text-center pr-2 sm:pr-0"><span className="bg-clip-text text-transparent bg-gradient-to-r from-c-acc to-c-main">どうすれば健康を<span  className="xxs:hidden"><br /></span>保てる？</span></h1>
  </div>
  </div>

</div>

        {/* BODY */}
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="col-span-1 md:col-span-2 mx-4 my-8">
            {children}

            <div id="scroll_bottom" className="h-px" />
            
            {isBlog
                    ? <Link to='/blog/' className="mt-2">
                      <p className="mt-8  text-lg underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
                      <ChevronDoubleRight className="h-6 w-6 align-middle inline  text-lx pb-0.5" />
                      <Home className="h-6 w-6 inline-block mr-1 align-text-bottom" />
                      コラムのトップに戻る
                      </p>
                      </Link>
              
                    : 
                    isNotFound
                      ? <></>
                      :
                      <Link to={pathToTop} >
                      <button type='button'
                      className="border border-gray-500 hover-hover:hover:bg-gray-200 active:bg-gray-200  text-center py-2 px-4 my-8 rounded font-semibold shadow">
                      <BackArrow className="h-6 w-6 align-text-bottom inline-block  text-lx " />
                      <Home className="h-6 w-6 inline-block  align-text-bottom" />
                      {pathToTopDesc}
                    </button>
                  </Link>
                    
                }


          </div>

          <div className="col-span-1 m-2 p-2 border-t-2 border-gray-500 border-dotted md:border-none">
            {lang === `ja`
              ? <SideMenu />
              : <SideMenuE />
            }

          </div>


        </div>


        { lang === `ja` 
                    ? <Footer langlink='/en/' />
                    : <FooterEn langlink='/' />
        }

      </main>
    </div>


  );
}

LayoutBlogTop.defaultProps = {
  lang: `ja`,
  isBlog: false,
  pathname: `/`,
  isNotFound: false,
};

LayoutBlogTop.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string,
  isBlog: PropTypes.bool,
  pathname: PropTypes.string,
  isNotFound: PropTypes.bool
};

export default LayoutBlogTop;