import React from "react";

import ExternalLink from "../static/svg/icon-external-link.inline.svg";

const Attribution = ({ imgHref, ccHref, by, ...props }) => (

    <div className="text-xs">

        <a
            rel="nofollow noreferrer"
            href={imgHref} 
            target="_blank"
            className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
            Image
            <ExternalLink className="h-4 w-4 inline-block mr-1 text-base align-bottom" />
        </a>
        {by} 
        <a rel="nofollow noreferrer"
            href={ccHref}
            target="_blank"
            className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
            CC BY
            <ExternalLink className="h-4 w-4 inline-block mr-1 text-base align-bottom" />
        </a>

    </div>

  );

export default Attribution;






