import React from 'react'
import {  graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Mail from "../static/svg/icon-mail.inline.svg";
import Call from "../static/svg/icon-call.inline.svg";
import ExternalLink from "../static/svg/icon-external-link.inline.svg";



import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";
import NishiogiMap from "../components/nishiogiMap";
import KasaiInfoBanner from '../components/kasaiInfoBanner';
import BusinessCalendar from '../components/businessCalendar';




function Nishiogi({data, pageContext, location}) {

  const { breadcrumb: { crumbs } } = pageContext;
  
  const {pathname} = location;
  const pagetitle = "ご案内";
  const description = "西荻窪駅徒歩５分の整体院・ぺんぎん堂のご案内。杉並区で、肩こり・首痛・頭痛・腰痛などでお困りのかたへ。";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

  // 葛西のチラシかどうか確認
  const searchParams = new URLSearchParams( location.search )

  const isKasai =  ( ( ( searchParams.get("utm_source") === "flyer" ) &&
       ( searchParams.get("utm_medium") === "qrcode" ) &&
       ( searchParams.get("utm_campaign") === "kasai") )
        ? true : false )

    return (
      <div>

        <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}  >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />

          <div>
            <h1 className="heading-b">ご案内</h1>

            { isKasai ? <KasaiInfoBanner urlpath={location.pathname} /> : null }

            <p className="text-lg">JR中央線／総武線・西荻窪駅から徒歩5分</p>

            <p className="my-1"><span className="font-semibold">営業日：</span>月曜日・火曜日・木曜日・第三土曜日</p>
            <p className="my-1"><span className="font-semibold">１月の土曜営業日：</span> 1/18 に営業いたします。</p>
            <div className="mb-2">
          
            <BusinessCalendar />
            </div>
            <p className="mt-1 mb-0"><span className="font-semibold">営業時間：</span> 10:00～18:00</p>
            <p className="mb-2 mt-0 text-sm">最終受付は17時です。17時以降に受付できる日もあります。ご希望のかたはお問い合わせください。</p>
            <p className="my-1"><span className="font-semibold">料金：</span>施術料 初回 8,800円、２回目以降 6,600円</p>
            <p className="mt-1 mb-3"><span className="font-semibold">時間：</span>初回 約90分、2回目以降 約60分</p>




            <p className="my-1 mt-3"><span className="font-semibold">住所：</span>〒{data.site.siteMetadata.postalcode} &nbsp; {data.site.siteMetadata.address}<br />西荻オステオパシーセンター内</p>

            <p className="my-1 font-semibold mt-3">ご予約・お問い合わせ：</p>
            <div className="sm:pointer-events-none">
            <a href={`tel:${data.site.siteMetadata.tel}`.replace(/\s+/g, "")}>
              <p className="text-2xl underline font-semibold sm:no-underline mb-0 sm:m-2 text-darkteal">
                <Call className="inline h-6 w-6 pb-0.5 ml-1" />
                {data.site.siteMetadata.tel}
              </p>
            </a>
            </div>
            <p className="mt-0 text-sm sm:hidden">（タップで電話できます。）</p>
            <p className="mt-0 mb-1">お電話受付時間 平日・土曜日 10:00~18:00</p>
            <p>施術中などで、お電話に出れない場合がございます。留守番電話にメッセージをお願いいたします。折り返し、ご連絡いたします。</p>


            <AnchorLink to="/contact/#form" >
              <button type='button' className="border border-emerald-600 bg-emerald-500 text-white hover-hover:hover:bg-emerald-600 active:bg-emerald-600 text-center my-4 py-2 px-4 rounded font-semibold shadow ">

                <Mail className="h-6 w-6 inline-block mr-2 align-bottom" />
                ウェブからのお問い合わせ

              </button>
            </AnchorLink>

            <div className="my-3" />





            <h2 id="access" className="heading-l">アクセス</h2>

            <p className="mt-4">JR中央線・総武線 西荻窪駅南口より、徒歩5分です。</p>
            <p>路線バスでお越しの方も、西荻窪駅よりお越しください。</p>
            <ul className="list-disc pl-6">

              <li>久我山方面から：<a href="https://www.city.suginami.tokyo.jp/_res/projects/default_project/_page_/001/005/011/kaedejikoku.pdf" target="_blank" rel="noreferrer" className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">すぎ丸 かえで路線    <ExternalLink className="inline w-4 h-4 align-text-bottom pb-0.5" />
              </a> </li>
              <li>大泉学園、上石神井、吉祥寺、井荻方面から：西荻窪駅までの路線バスがあります。関東バスのページで検索してください。<a href="https://www.kanto-bus.co.jp/regular/" target="_blank" rel="noreferrer" className="underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">関東バス<ExternalLink className="inline w-4 h-4 align-text-bottom pb-0.5" /></a></li>

            </ul>
            <p className="mt-6 underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
              <a href="https://www.google.com/maps/place/%E8%BA%AB%E4%BD%93%E3%81%AE%E8%AA%BF%E6%95%B4+%E3%81%BA%E3%82%93%E3%81%8E%E3%82%93%E5%A0%82%E3%82%AA%E3%82%B9%E3%83%86%E3%82%AA%E3%83%91%E3%82%B7%E3%83%BC/@35.7008897,139.6001098,16z/data=!4m2!3m1!1s0x6018efb916a1ec21:0x130ac75b617c9130" target="_blank" rel="noreferrer" >GoogleMapはこちら
                <ExternalLink className="inline  w-4 h-4 align-text-bottom pb-0.5" />
              </a></p>
            <p className="mt-2">
              JR中央線／総武線・西荻窪駅から徒歩５分です。
            </p>

            <div className="mb-6 responsive-map">
<StaticImage
    src="../images/NishiogiMap.png"
    alt="西荻窪駅南口からぺんぎん堂西荻窪院までの地図"
    placeholder="tracedSVG"
    layout="constrained"
    width={534}
 />
  </div>
            
            <NishiogiMap lang='ja' />

</div>
        </LayoutSub>
      </div>

      
    )
}

export default Nishiogi;

export const query = graphql`
query {
  site {
  siteMetadata {
  siteUrl
  address
  tel
  postalcode
}
}
}
`