import React from 'react';
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image' 


import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import Update from "../static/svg/icon-update.inline.svg";

import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";
import LayoutBlogTop from "../components/layoutBlogTop";






function Blog ({ location, data, pageContext })  {

  const {pathname} = location;
  const pagetitle = "身体のコラム・ブログ";
  const description = "西荻窪の整体院・ぺんぎん堂：身体に関するコラム。腰痛や肩こりを予防するためのちょっとしたコツも紹介してます。";

  const { breadcrumb: { crumbs } } = pageContext;

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

  {/*
      const filtered = (.allMdx).filter(article => {
          if (article.frontmatter.tags.includes("posture")) {
              return article
          }
    })
  */}

{/*
  // data.allMdx.nodes.map((node) => (
  const filtered = data.allMdx.nodes.filter(node => {

    if (Array.isArray(node.frontmatter.tags)) {
      if (node.frontmatter.tags.includes("posture")) {
        if (node.slug !== "what-is-good-posture/") {
        return node
        }
      }
    }

  })
*/}

  // data.allMdx.nodes.map((node) => (
    const filtered = data.allMdx.nodes.filter(node => {

          if (node.slug !== "what-is-good-posture/") {
          return node
          }
     
  
    })

  const futured = data.allMdx.nodes.filter(node => {


      if (node.slug === "what-is-good-posture/") {
        return node
      }
    

  })

 // console.log(filtered)



    return (

        <LayoutBlogTop crumbs={crumbs} pathname={pathname} pathTop={pathTop}>
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />
          <section>
          <p className="py-2 px-3 mb-6 bg-c-main text-c-base text-lg sm:text-2xl lg:text-3xl font-semibold">不調のサインに気づいていますか？</p>

          <ul className="m-2"><li>「最近、寝てもすっきりしないな」</li>
          <li>「腰が重だるいなー」</li>
          <li>「気づくと肩に力がはいってる…」</li></ul>
<p className="mt-6">
そんなことを感じても、なんとなく我慢できてしまうので、そのままにしていませんか？</p>
<p>このコラムでは、</p>
  <ul className="ml-6 list-disc"><li>どうして不調のサインがでたのか？</li>
  <li>どんなセルフケアをすればいい？</li>
  <li>姿勢と健康の深~い関係</li></ul>

  <p>などをお伝えしています。</p>
  <hr className="border-1 border-c-acc mb-2 mt-8"/>
{/* 
          <p>寝れば疲れがとれる元気な体を手に入れたい、両親の健康が気になる、股関節/膝/足・腰/背中・肩・自律神経・本・天気痛</p> */}
         {/*  <div className="border-b-2 border-gray-600 border-dotted" /> */}

{/* <div className="grid grid-cols-2 items-start justify-items-center border-b-2 border-gray-600 border-dotted "> */}

{futured.map((node) => (


<article key={`${node.id}`} className="w-full border-b border-c-acc ">
                <div className="px-1.5 relative">
                  <div className="mx-auto my-3">
                    <Link to={`/blog/${node.slug}`}>
                    <GatsbyImage
                        image={node.frontmatter.hero_image.childImageSharp.gatsbyImageData}
                        alt={node.frontmatter.hero_image_alt}
                        placeholder="blurred"
                      />
                    </Link>
                  </div>
<div className="absolute bottom-14 bg-c-sub w-full">
                  <div className="py-2  text-c-base hover-hover:hover:bg-c-acc active:bg-c-acc  my-auto">
                    <Link to={`/blog/${node.slug}`}>
                      <h2 className="ml-2   text-lg">{node.frontmatter.title}</h2>
                      <p className=" pr-2 text-right">

続きを読む
<ChevronDoubleRight className="h-5 w-5 align-middle inline-block mr-0.5  " /></p>

                    </Link>
                  </div>
                  </div>
                  {/* 
                  <div className="absolute bottom-11  text-c-acc text-lg text-right w-full">
                    <Link to={`/blog/${node.slug}`} className="bg-c-base py-2 pr-2">

                      続きを読む
                      <ChevronDoubleRight className="h-5 w-5 align-middle inline-block mr-0.5  " />


                    </Link>
                  </div>
*/ }

                </div>
              </article>

))}


          <div className="grid grid-cols-1 items-start justify-items-center border-b border-c-acc ">
            {filtered.map((node) => (
              <article key={`${node.id}`} className="w-full border-b border-c-acc ">
                <div className="flex flex-row flex-nowrap items-start justify-items-center px-1.5 ">
                  <div className="mx-auto my-3 w-1/4">
                    <Link to={`/blog/${node.slug}`}>
                      <GatsbyImage
                        image={node.frontmatter.hero_image.childImageSharp.gatsbyImageData}
                        alt={node.frontmatter.hero_image_alt}
                        placeholder="blurred"
                      />
                    </Link>
                  </div>

                  <div className="pb-2 hover-hover:hover:bg-gray-100 active:bg-gray-100 flex-1 my-auto">
                    <Link to={`/blog/${node.slug}`}>
                      <h2 className="ml-2 mr-0.5 mt-1 mb-0  text-base">{node.frontmatter.title}</h2>

                    </Link>
                    <p className="mt-0 mb-1 mr-2 text-right  text-sm">
                        <Link to={`/blog/${node.slug}`} >

                          続きを読む
                          <ChevronDoubleRight className="h-4 w-4 align-middle inline-block mr-0.5  " />


                        </Link>
                      </p>
                  </div>


                </div>
              </article>
            ))}
</div>

{/*
  <div className="flex flex-row flex-wrap">
          {
            data.allMdx.nodes.map((node) => (

 
              <article key={`${node.id}`} className="w-1/2 lg:w-1/3 border-b border-c-acc ">
<div className="flex flex-row flex-wrap items-start justify-items-center p-2">


                    <div className="mt-2 mb-1 ml-1 mr-0.5 pt-3">
                      <Link to={`/blog/${node.slug}`}>
                        <GatsbyImage
                          image={node.frontmatter.hero_image.childImageSharp.gatsbyImageData}
                          alt={node.frontmatter.hero_image_alt}
                          placeholder="blurred"
                        />
                      </Link>
                    </div>
                    
                    <div className="pb-2 hover-hover:hover:bg-gray-100 active:bg-gray-100">
                      <Link to={`/blog/${node.slug}`}>
                        <h2 className="ml-2 mr-0.5 mt-1 mb-0  text-base">{node.frontmatter.title}</h2>

                      </Link>
                                            <p className="mt-2 mb-1 mr-2 text-right  text-sm">
                        <Link to={`/blog/${node.slug}`} >

                          続きを読む
                          <ChevronDoubleRight className="h-4 w-4 align-middle inline-block mr-0.5  " />


                        </Link>
                      </p>
                    </div>
               
</div>


                  








              </article>

    
       
            ))
          }
          </div>
        */}
        
          </section>
        </LayoutBlogTop>


      
    )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___lastmod, order: DESC}) {
      nodes {
        frontmatter {
          title
          lastmod(formatString: "YYYY年M月D日")
          description
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 800
                transformOptions: {cropFocus: CENTER}
                aspectRatio: 1
                placeholder: TRACED_SVG
              )
            }
          }
          tags
        }
        slug
        id

        
      }
    }
  }

  
  
`
export default Blog;