import React from "react";
import { useStaticQuery,graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "@emotion/styled"

import Mail from "../static/svg/icon-mail.inline.svg"
import Call from "../static/svg/icon-call.inline.svg"

import PenLogo from "../static/svg/pen-logo.inline.svg";
import LineLogo from "../static/svg/line.inline.svg";

// Style 定義
const SvgBg = styled.div`
  background-color: #fcf9ce;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%23e4e0e8' fill-opacity='0.5' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 12px, 16px;
`;

function CtaEn(  ) {

  const data = useStaticQuery(graphql`
  query ctaEnQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  `);

  const  tel   = (data.site.siteMetadata.tel).replace(/\s+/g, "");


  return (
<SvgBg>
    <div className="text-center pt-4 pb-8">

      <p className="pt-1 pb-0 text-base xxs:text-lg mb-0">
        <PenLogo className="inline-block mr-1.5 -mt-1.5 width='45px' height='45px'" /><span className="m:hidden"><br /></span>
        Want to talk to us about your lower back or neck pain?&nbsp;
        
        We&apos;re here to help.
      </p>
      <div className="sm:pointer-events-none">
      <a href={`tel:${tel}`}>
      <p className="mt-0 mb-0 text-darkteal">
        <Call className="h-6 w-6 fill-current inline-block mr-1 mb-0 align-text-bottom" />
        <span className="font-bold text-2xl">{data.site.siteMetadata.tel}</span>
      </p>
      </a>
      </div>
      <p className="my-0 text-sm sm:hidden">(Tap to call)</p>
      <p className="mt-0 mb-2">telephone reception hours：        <span className="sm:hidden"><br /></span>Mon to Sat 10:00~18:00</p>

      <AnchorLink to="/en/contact/#form" >
        <button type='button' className="border border-emerald-600 bg-emerald-500 text-white hover-hover:hover:bg-emerald-600 active:bg-emerald-600  text-center my-1.5 py-1.5 px-4 rounded font-semibold shadow-lg  cta-view">

          <Mail className="h-5 w-5 inline-block mr-2 mb-0 align-text-bottom" />
          Online Form

        </button>
      </AnchorLink>

      <p className="mb-0.5">You can contact us via LINE.</p>
      <p className="inline-block align-middle my-0">LINE ID:@413srlsz&nbsp;
      <a href='https://lin.ee/7RK303'>
        <span className="inline-block align-middle">
          <LineLogo
            height="36"
            width="116" /></span>
      </a>
      </p>

    </div>
    </SvgBg>
    


  );
}



export default CtaEn;