import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import BlogCards from "./blogCards";
import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import Mail from "../static/svg/icon-mail.inline.svg"
import Calendar from "../static/svg/icon-calendar.inline.svg";


function Topix(  ) {



  return (
    <div className="text-left mx-3 md:mx-4 mt-8">

            {/* ここから========> */}
            <p>
        <span className="marker">2024/6/17 ７月の営業日</span>
      </p>
      <p>7月の土曜営業日は、7月20日です。</p>
      <p>7月15日（海の日）はお休みさせていただきます。</p>


      <p className="mb-5">
        <AnchorLink
          to="/contact/#form"
          className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900"
        >
          <ChevronDoubleRight className="h-6 w-6 inline pb-0.5" />
          <Mail className="h-5 w-5 inline pb-0.5 mr-0.5" />
          ご予約はこちら
        </AnchorLink>
      </p>


      {/* ここまで <========== */}

      {/* ここから========> */}
      {/*
      <p>
        <span className="marker">2023/8/24 西荻窪院 ９月の営業日</span>
      </p>
      <p>西荻窪院の9月の土曜営業日は、9月16日です。</p>
      <p>9月18日（敬老の日）も営業いたします。</p>


      <p className="mb-5">
        <AnchorLink
          to="/contact/#form"
          className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900"
        >
          <ChevronDoubleRight className="h-6 w-6 inline pb-0.5" />
          <Mail className="h-5 w-5 inline pb-0.5 mr-0.5" />
          ご予約・お問い合わせフォームはこちら
        </AnchorLink>
      </p>

      <p className="pb-0.5 mb-5">9月28日（木）は、お休みさせていただきます。</p>
      */}

      {/* ここまで <========== */}



      {/* <p>
        <span className="marker">2023/1/13 葛西院 価格改定のお知らせ</span>
      </p>
      <p>
        昨今の光熱費の高騰などに伴い、大変恐縮ではございますが、2023年2月1日より、葛西院の施術料金を下記の通り改定させていただきます。
        <br />
        お客様へご負担をおかけいたしますこと、心よりお詫び申し上げます。
      </p>
      <p className="ml-3">
        施術料 初回 6,714円を7,000円に改定
        <br />
        ２回目以降 5,714円を6,000円に改定
      </p>
  <p className="mb-5">今後とも、どうぞよろしくお願い申し上げます。</p> */}

      {/* 
      <p >
        <span className="marker">
        2022/12/22  葛西院 年末年始の営業日
        </span>
      </p>
      <p>葛西院は、年末は12月31日（土）まで、年始は1月6日（金）から営業いたします。</p>
 
      <p className="mb-4">
        <a href="https://coubic.com/k-osteopathyroom/182753/book" target="_blank" rel="noreferrer" className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900">
          <ChevronDoubleRight className="h-6 w-6 inline pb-0.5" />
          <Calendar className="h-5 w-5 inline pb-0.5 mr-0.5" />
          オンライン予約（葛西）はこちら
        </a>
      </p>
  */}

      {/*

      <p className="mt-4">
        <span className="marker">2022/9/6  西荻窪院 ９月の営業日</span>
      </p>
      <p>
      西荻窪院は、8月4日（木）、8月18日（木）の午後から8月22日（月）までお休みさせていただきます。</p>
      <p>
      8/18日（木）は、午前中は営業しております。</p>
      <p>
      8/11日（山の日）も営業いたします。</p>
      
*/}

      {/*
      <p className="mt-4">
        <span className="marker">2022/6/19  葛西院 ７月の営業日</span>
      </p>
      <p>
      葛西院は、7月6日（水）・27日（水）はお休みさせていただきます。
      </p>
      
      <p>
      オンラインでご予約いただけます。
      
      </p>
      <p>
        <a href="https://coubic.com/k-osteopathyroom/182753/book" target="_blank" rel="noreferrer" className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900">
          <ChevronDoubleRight className="h-6 w-6 inline pb-0.5" />
          <Calendar className="h-5 w-5 inline pb-0.5 mr-0.5" />
          オンライン予約（葛西）はこちら
        </a>
      </p>
        */}


    </div>
  );
}



export default Topix;