import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import PenLogo from "../static/svg/pen-logo.inline.svg";


function LpSciaticaHeroImg() {
    return (
        <div className="max-w-3xl text-center m-auto">


            <div className="w-full mb-8">
                <StaticImage
                    src="../images/lp-top-sciatica.png"
                    alt="坐骨神経痛に悩む女性"
                    layout="constrained"
                    loading="eager"
                    placeholder="blurred"

                />
            </div>

            <div className="arrow_box_t py-4 sm:p-3 text-slate-900 ">
                <p className="text-center font-bold text-2xl sm:text-3xl md:text-rxl"><PenLogo className="inline-block mr-1.5 -mt-1.5 width='10px' height='10px'" /><span className="marker">こんな悩み、<span className="md:hidden"><br /></span>ひとりで抱えていませんか？</span></p>
                <ul className="mx-8 py-3 text-base list-outside list-disc mb-0 text-left sm:text-lg md:text-xl font-semibold">
                <li className="my-2">朝からお尻や太もも裏の痛みが気になる</li>
                    <li className="my-2">座っているだけなのに、じわじわ痛みが広がる</li>
                    <li className="my-2">痛み止めや湿布には、もう頼りたくない
                    </li>
                    <li className="my-2">ストレッチや健康グッズだけでは、期待した効果を得られなかった
                    </li>
                    <li className="my-2">痛みで気分が乗らなくても、いつも我慢してしまう
                    </li>
                    </ul>
                <p className="text-center  text-xl sm:text-2xl md:text-3xl">
                    「そうそう、これ全部私のこと」
                    <span className="sm:hidden"><br /></span>
                    と思わず頷いたあなたのためのページです。
                </p>

            </div>


        </div>
    )
};

export default LpSciaticaHeroImg;