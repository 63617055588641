import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import ExternalLink from "../static/svg/icon-external-link.inline.svg"
import Call from "../static/svg/icon-call.inline.svg"

function InfoNishiogi(  { isLp } )  {

  const { site } = useStaticQuery(graphql`
  query NishiogiAddressQuery {
    site {
      siteMetadata {
        postalcode,
        address,
        tel
      }
    }
  }
`);

  return (

    <>

{ (isLp ) ?
      <h2 className="heading-b my-6">
                ぺんぎん堂のご案内
      </h2>
        :
        <h2 className="heading-b my-6 text-center">
        ぺんぎん堂のご案内
</h2>
}


      <p className=" text-lg font-semibold">

        JR中央線／総武線・西荻窪駅から徒歩5分</p>
      <p>〒&nbsp;{site.siteMetadata.postalcode}<br />
        {site.siteMetadata.address}<br />
        西荻オステオパシーセンター内</p>

        <div className="sm:pointer-events-none">
      <a href={`tel:${site.siteMetadata.tel}`.replace(/\s+/g, "")}>
        <p className="text-darkteal text-xl underline sm:no-underline mb-0 sm:m-2">
          <Call className="w-6 h-6 mb-0.5 inline pb-0.5 ml-1" />
          <span className="font-bold">{site.siteMetadata.tel}</span>
        </p>
      </a>
      </div>
      <p className="mt-0 text-sm sm:hidden">（タップで電話できます。）</p>
      <p>
        <span className="font-semibold">営業日：</span>月曜日・火曜日・木曜日・第三土曜日<br />
        <span className="font-semibold">営業時間：</span>10:00～18:00<br />
        &nbsp;&nbsp;最終受付は16時半です。17時以降に受付できる日もあります。ご希望のかたはお問い合わせください。
      </p>
      <p>
        <span className="font-semibold">料金：</span>施術料 初回 8,800円、２回目以降 6,600円<br />
        <span className="font-semibold">時間：</span>初回 約90分、2回目以降 約60分
      </p>
      <div id="map" />
      <p className="mt-6 text-lg underline text-c-main hover-hover:hover:text-c-acc active:text-c-acc">
        <a href="https://www.google.com/maps/place/%E8%BA%AB%E4%BD%93%E3%81%AE%E8%AA%BF%E6%95%B4+%E3%81%BA%E3%82%93%E3%81%8E%E3%82%93%E5%A0%82%E3%82%AA%E3%82%B9%E3%83%86%E3%82%AA%E3%83%91%E3%82%B7%E3%83%BC/@35.7008897,139.6001098,16z/data=!4m2!3m1!1s0x6018efb916a1ec21:0x130ac75b617c9130" target="_blank" rel="noreferrer" >GoogleMapはこちら
          <ExternalLink className="h-5 w-5 align-middle inline pb-0.5 ml-1" />
        </a></p>
      <p className="mb-0">
        JR中央線／総武線・西荻窪駅から徒歩５分です。
      </p>
      <div className="mb-6 responsive-map">
        <StaticImage
          src="../images/NishiogiMap.png"
          alt="西荻窪駅南口からぺんぎん堂西荻窪院までの地図"
          placeholder="tracedSVG"
          layout="constrained"
          width={534}
        />
      </div>


    </>
  );
}

InfoNishiogi.defaultProps = {
  isLp: false,
};



export default InfoNishiogi;