exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-access-js": () => import("./../../../src/pages/en/access.js" /* webpackChunkName: "component---src-pages-en-access-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-kasai-js": () => import("./../../../src/pages/en/kasai.js" /* webpackChunkName: "component---src-pages-en-kasai-js" */),
  "component---src-pages-en-nishiogi-js": () => import("./../../../src/pages/en/nishiogi.js" /* webpackChunkName: "component---src-pages-en-nishiogi-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-profile-js": () => import("./../../../src/pages/en/profile.js" /* webpackChunkName: "component---src-pages-en-profile-js" */),
  "component---src-pages-en-thanks-js": () => import("./../../../src/pages/en/thanks.js" /* webpackChunkName: "component---src-pages-en-thanks-js" */),
  "component---src-pages-en-therapy-js": () => import("./../../../src/pages/en/therapy.js" /* webpackChunkName: "component---src-pages-en-therapy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kasai-js": () => import("./../../../src/pages/kasai.js" /* webpackChunkName: "component---src-pages-kasai-js" */),
  "component---src-pages-lp-autonomic-nervous-js": () => import("./../../../src/pages/lp-autonomic-nervous.js" /* webpackChunkName: "component---src-pages-lp-autonomic-nervous-js" */),
  "component---src-pages-lp-holistic-js": () => import("./../../../src/pages/lp-holistic.js" /* webpackChunkName: "component---src-pages-lp-holistic-js" */),
  "component---src-pages-lp-osteopathy-js": () => import("./../../../src/pages/lp-osteopathy.js" /* webpackChunkName: "component---src-pages-lp-osteopathy-js" */),
  "component---src-pages-lp-sciatica-js": () => import("./../../../src/pages/lp-sciatica.js" /* webpackChunkName: "component---src-pages-lp-sciatica-js" */),
  "component---src-pages-lp-thanks-js": () => import("./../../../src/pages/lp-thanks.js" /* webpackChunkName: "component---src-pages-lp-thanks-js" */),
  "component---src-pages-nishiogi-js": () => import("./../../../src/pages/nishiogi.js" /* webpackChunkName: "component---src-pages-nishiogi-js" */),
  "component---src-pages-osteopathy-js": () => import("./../../../src/pages/osteopathy.js" /* webpackChunkName: "component---src-pages-osteopathy-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-therapy-js": () => import("./../../../src/pages/therapy.js" /* webpackChunkName: "component---src-pages-therapy-js" */),
  "component---src-pages-top-js": () => import("./../../../src/pages/top.js" /* webpackChunkName: "component---src-pages-top-js" */),
  "component---src-pages-voices-js": () => import("./../../../src/pages/voices.js" /* webpackChunkName: "component---src-pages-voices-js" */)
}

