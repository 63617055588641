import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Mail from "../../static/svg/icon-mail.inline.svg";
import Call from "../../static/svg/icon-call.inline.svg";
import Calendar from "../../static/svg/icon-calendar.inline.svg";

import LayoutSub from "../../components/layoutSub";
import SEO from "../../components/seo";
import { HookForm } from "../../components/hookForm";


import LineLogo from "../../static/svg/line-en.inline.svg";



function Contact({ location, pageContext }) {
  const { site } = useStaticQuery(graphql`
  query SiteContactEngQuery {
    site {
      siteMetadata {
        postalcode,
        address,
        tel,
        kpostalcode,
        kaddress,
        kaddress2,
        ktel
      }
    }
  }
`);


  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "Contact us";
  const description = "Contact us.  We'd love to hear from you!  You can find Penguindo Osteopathy at Nishiogikubo, Suginami-ku.";

  return (


    <LayoutSub crumbs={crumbs} pathname={pathname}  lang='en'>
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
        lang='en'
      />

      <section>
        <h1 className=" my-2 heading-b">Contact Us</h1>

        <p>To check availability and book appointments, please use the form below, send us LINE message or contact us by phone.</p>
 
        <div className="sm:pointer-events-none">
        <a href={`tel:${site.siteMetadata.tel}`.replace(/\s+/g, "")}>
          <p className="text-2xl text-darkteal font-semibold underline sm:no-underline my-0 ">
            <Call className="w-6 h-6 inline pb-0.5 ml-1" />
            {site.siteMetadata.tel}
          </p>
        </a>
        </div>
        <p className="my-0"><span className=" font-semibold">telephone reception hours：</span><span className="inline lg:hidden"><br />&nbsp;&nbsp;</span>Monday to Saturday 10:00~18:00</p>
        <p className="mt-0">If you come through to the answering machine, please leave a message and we will call you back as soon as possible.</p>

        <AnchorLink to="#form" >
          <button type='button' className="border border-emerald-600 bg-emerald-500 text-white hover-hover:hover:bg-emerald-600 active:bg-emerald-600 text-center my-4 py-2 px-4 rounded font-semibold shadow">

            <Mail className="h-6 w-6 inline-block mr-2 align-bottom" />
            Send Us Message

          </button>
        </AnchorLink>



        <p className="mb-0.5">You can reach us by LINE.&nbsp;LINE ID:@413srlsz</p>
        <p className="inline-block align-middle mb-0.5 mt-0">
        
          <a href='https://lin.ee/7RK303'>
            <span className="inline-block align-middle">
              <LineLogo
                height="36"
                width="116" /></span>
          </a>
        </p>
        <p className="mt-0.5">1. Add us as a friend on LINE by clicking the button above.<br />
          2. Send us a message.
        </p>
        
      <div id="form" className="my-8 pt-14 md:pt-16">
        <HookForm lang="en" />
        </div>

      </section>
    </LayoutSub>
      );
}

export default Contact;