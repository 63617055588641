import React from "react";
import {  graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

import LayoutTop from "../components/layoutTop";
import SEO from "../components/seo";
import VoiceShort from "../components/voiceShort";
import Topix from "../components/topix";
import Recommend from "../components/recommend";
import Info from "../components/info";
import InfoLink from "../components/infolink";
import Cta from "../components/cta";
import ForBeginner from "../components/forBeginner";


function Top({ location }) {
  const {pathname} = location;

  return (
    <LayoutTop lang="ja" pathTop="/top/">
      <SEO pageurl={pathname} isNoindex />

      <section className="text-center">

        <h1 className="heading-b my-6">
        <span className="leading-none text-sm xxs:text-base xs:text-lg sm:text-xl lg:text-2xl">
          肩こり、頭痛、腰痛に：西荻窪の整体
          <span className="hidden lg:inline">&nbsp;</span>
        </span>
        <span className="lg:hidden"><br /></span>
        <span className="text-lg xxs:text-xl xs:text-2xl">
        ≪ぺんぎん堂オステオパシー≫
        </span></h1>

        <div className="text-left mx-1 md:mx-4">
          <div className="overflow-hidden bg-pink-50 p-1">
            <StaticImage
              src="../images/mask.png"
              alt="新型コロナウィルス感染症対策"
              placeholder="blurred"
              layout="constrained"
              width={50}
              className="float-left mr-1"
            />
            <p className="m-0 px-4">安心して整体を受けていただけるように新型コロナウィルス感染症対策に取り組んでおります。</p>
            <Link to='/covid19/'
            state={{ pathTop: '/top/' }}  className="underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline pb-0.5" />当院のコロナウィルスへの対策はこちらへ
            </Link>
          </div>
          <h2 className="heading-b my-6">慢性的な肩こり、首の痛みや頭痛に困っていませんか？</h2>
          <p>
            ぺんぎん堂のホームページをご覧いただき、ありがとうございます。
          </p>
          <p className="sm:mt-3">整骨院や整体院で、こんな言葉を良く見かけませんか？</p>
          <ul className="list-disc">
            <li>あなたの痛み、その原因は痛い場所にはありません</li>
            <li>骨盤や背骨を矯正して、根本から治します</li>
          </ul>
          <p>
            本当にそのとおりで、原因が痛い場所にないことは多いです。こんな言葉も目にしたことはありませんか？</p>
            <ul className="list-disc">
            <li>「腰痛の原因は<span className="marker">大腰筋</span>が9割」</li>
            <li>「<span className="marker">骨盤の歪み</span>が腰の痛みを引き起こす」</li>
            <li>「肩こりの原因は<span className="marker">ストレートネック</span>」</li>
            </ul>
          <p>骨盤、背骨、筋肉、関節、…原因になりそうな場所は、<span className="marker">まだ他にもあるんです</span>。では、どこにあるのでしょうか？&#129300;</p>

          <div className="overflow-hidden">
            <StaticImage
              src="../images/seitai-session1.jpg"
              alt="整体・オステオパシー施術の様子"
              placeholder="blurred"
              layout="constrained"
              width={300}
              className="sm:float-left mr-5 mt-3 mb-4 ml-2"
            />


            <p>痛みや不調の原因は、骨盤・背骨、筋肉や関節だけでなく、<span className="marker">リンパ、内臓、頭（頭蓋骨や脳脊髄液）の動き</span>のこともあるんです。</p>
            <p>姿勢が悪くて、内臓の動きが悪くなってしまっていることが原因で腰痛になることもあります。</p>
            <p>ぺんぎん堂では、筋肉や関節・背骨はもちろん、リンパの流れや内臓を支える組織、頭も含めて検査して、全身調整します。       <span className="marker">骨盤と背骨だけの調整ではだめだったコリや痛みに、本物の全身調整をしてみませんか？</span></p>
          </div>


          <hr className="my-2" />

          <p>ぺんぎん堂では、<span className="marker">全身を検査して原因を探して、肩や腰のコリをほどいていきます</span>。</p>

          <p>直接マッサージをしたり、揉みほぐしたりするのとは違った方法で、その頑固なコリをスッキリ解消します。無理やりぐいぐい押したりしないので、<span className="marker">痛みも「揉み返し」もなく安心</span>です。</p>

          <p>肩や腰のがんこなコリの原因は、</p>
          <ul className="list-disc">
            <li>血液・リンパの流れが悪い</li>
            <li>身体全体のバランスの崩れ</li>
            <li>自律神経の乱れ</li>
          </ul>
          <p>など様々なものがあります。</p>
          <p>
            複数の原因が重なって、ますます頑固に硬くなっていくこともあります。</p>

          <p>ぺんぎん堂にご来院されたクライアント様から、整体・施術が終わった後に、</p>
          <ul className="list-disc">
            <li>「首も軽くなったんですが、<span className="marker">とにかく目がスッキリ！</span>」</li>
            <li>「<span className="marker">なんとなく頭がぼーっとしてたのが、スッとしました。</span>」</li>
          </ul>
          <p>という感想をいただくことがあります。</p>
          <p>
            首や肩が凝り固まっていると、首から上の血流が悪くなるので、目が疲れたり、頭痛につながることがあるんです。</p>
          <p>「たかが肩こり」と思っていても、ひどい状態が続いて慢性化してしまうと、<span className="marker">頭痛や吐き気にまで症状が悪化</span>してしまうこともあります。</p>
          <p>そんなつらい状態になる前に、<span className="marker">ガチガチで不快な肩こりや首のコリを原因から取り除いて</span>しまいませんか？</p>

          <hr className="my-2" />

          <VoiceShort />
          <hr className="my-2" />

          <ForBeginner />

          <Topix />

          <h2 className="heading-b my-6">ぺんぎん堂の整体：オステオパシー</h2>

          <p><span className="marker">「整体」と聞くと何を想像されますか？</span></p>
          <p>カイロプラクティック、中国整体、骨盤調整、もみほぐし整体など、たくさんありますが…</p>
          <p>ぺんぎん堂で行っている整体は、<span className="marker">アメリカ発祥の”オステオパシー”</span>という方法です。</p>

          <div className="mt-4">
            <h3 className="heading-l">普通の整体と、オステオパシーは何が違うのか？</h3>
          </div>

          <ul className="list-disc">
            <li><span className="marker">頭の骨の動き</span>も含めて、本当の意味での全身を施術</li>
            <li>筋骨格だけでなく、<span className="marker">リンパの流れや脳脊髄液の流れ</span>もチェックして施術</li>
            <li><span className="marker">内臓</span>を支えている靱帯や膜も施術する</li>
          </ul>

          <p>上記は、普通の整体とオステオパシーの違いの代表的なものです。</p>

          <p>リンパドレナージュをしたり、頭専門のヘッドスパをしたりするところは比較的多くありますが、<span className="marker">筋肉も、脊柱も、関節も、リンパも、頭も、内臓も、全て施術の対象としているのはオステオパシーだけ！</span>です。</p>

          <div className="mt-4">
            <h3 className="heading-l">ぺんぎん堂の施術者の資格</h3>
          </div>

          <p>整体は色々ありますが、どれも国や自治体が定めた資格制度がないため、それぞれのジャンルの整体を始めるまでの教育の内容や、整体師のレベルはかなりまちまちです。<span className="marker">整体院や施術者選びは、慎重に調べてから</span>というかたも多いと思います。</p>
          <div className="overflow-hidden">

            <StaticImage
              src="../images/cranial-field.jpg"
              alt="頭蓋領域のオステオパシー"
              placeholder="blurred"
              layout="constrained"
              className="max-w-17 sm:float-left mr-5 mt-3 mb-4 ml-2 w-full"
            />

            <p>ぺんぎん堂の施術者は、アメリカのオステオパシードクターが公認している3年制のオステオパシー専門教育機関を卒業し、オステオパシーの国際ライセンスも取得しております。</p>
            <p>解剖学や生理学なども含めて、<span className="marker">基礎から学んだうえでの安全な整体</span>です。どうぞご安心してご来院ください。</p>
          </div>

          <p>オステオパシーは、発祥の地・アメリカだけでなく、ヨーロッパでも広く普及しており、イギリスでは<span className="marker">毎日 約30,000人</span>がオステオパシー施術を受けています。</p>

          <Link to='/osteopathy/' 
          state={{ pathTop: '/top/' }} className="text-lg underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900">
            <ChevronDoubleRight className="ml-2.5 w-4 h-4 align-middle inline  text-2lx pb-0.5" />オステオパシーについて詳しくはこちらへ
          </Link>

          <p>  オステオパシーは、身体の歪みなどの骨格の調整に加えて、<span className="marker">自律神経やリンパの流れなども含めて</span>全身を調整します。</p>
          <p>そのため、肩こり・腰痛や膝の痛みだけでなく、</p>
          <ul className="list-disc">
            <li>生理痛</li>
            <li>月経前症候群（PMS）</li>
            <li>疲れやすく眠りが浅い</li>
          </ul>
          <p>といったことでお困りのかたにもご来院いただいています。</p>
          <p>生理痛やPMSには、骨盤の歪みや硬さにはたらきかけていきます。<br />
            それだけでなく、血流やリンパの流れが悪くなり、<span className="marker">骨盤に溜まってしまった老廃物を取り除く</span>ために、骨盤より上の横隔膜や胸郭など身体の他の部位やリンパ管にもアプローチします。</p>
          <p>生理が始まる前から、そろそろかなと思ったら薬を飲まないと痛くてどうにもならない、というクライアントさんから、</p>
          <ul>
            <li>「薬は一日目だけで済むようになってきました。」</li>
            <li>「調子の良いときには薬を飲まないで大丈夫でした！」</li>
          </ul>
          <p>という嬉しいご報告もいただいています。</p>
          <p>
            ぺんぎん堂では、「手」を使って全身を検査して痛みや不調の原因をさぐり、全身のバランスを整えて、あなたの<span className="marker">本来の身体の機能を取り戻す</span>お手伝いをします。</p>

          <p>オステオパシーは、大正時代（一説によると明治時代末期）には日本に伝わっていたのですが、他の手技やテクニックと合わせた上で「整体術」として紹介されたため、日本ではあまり普及しませんでした。<br />
            まだ日本では知名度の低いオステオパシーですが、<span className="marker">確かな理論に基づいた歴史ある手技</span>です。オステオパシーで、<span className="marker">新しい健康づくり</span>を始めませんか？
          </p>

          <Link to='/profile/' 
          state={{ pathTop: '/top/' }} 
          className="mt-2">
            <p className="mb-0  text-lg underline text-blue-800 hover-hover:hover:text-blue-900 active:text-blue-900">
              <ChevronDoubleRight className="h-4 w-4 align-middle inline  text-lx pb-0.5" />
              施術者プロフィールはこちらへ
              </p>
              <StaticImage
              src="../images/profile.jpg"
              alt="ぺんぎん堂施術者プロフィール写真"
              placeholder="blurred"
              layout="constrained"
              width={150}
              className=""
            />

            </Link>


  <InfoLink />
  <Cta />

  

<Recommend />
<Info />
<Cta />
        </div>
      </section>
    </LayoutTop>
  );
}

export default Top;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
` 