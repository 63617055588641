import { useStaticQuery,graphql,Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Call from "../static/svg/icon-call.inline.svg";

import PenLogo from "../static/svg/pen-logo-text.inline.svg";
import PenLogoE from "../static/svg/pen-logo-text-en.inline.svg";


function Menu( props ) {

  const data = useStaticQuery(graphql`
  query menuQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  
  `);

  const [show, setShow] = useState(false);
  const showBelow=100;

  const changeShow = () => {

    if (window.pageYOffset > showBelow) {

      setShow(true)

    } else {

      setShow(false)

    }

  }

  useEffect(() => {

    window.addEventListener('scroll', changeShow, {passive: true})

    return () => window.removeEventListener('scroll', changeShow)

  }, [])



  const  tel   = (data.site.siteMetadata.tel).replace(/\s+/g, "");
  const { lang, pathTop, notShow }  = props;
  const linkToTopJ =  pathTop || '/';
  const linkToTop =  (lang === 'ja' ) ? linkToTopJ : '/en/';

  const mitem =    (lang === 'ja') ? ( [
    { route: linkToTopJ, title: "トップ", isPartiallyActive: false },
    { route: "/therapy/", title: "ぺんぎん堂の施術", isPartiallyActive: false },
    { route: "/faq/", title: "よくあるご質問", isPartiallyActive: false },
    { route: "/nishiogi/", title: `ご案内`, isPartiallyActive: false },
    { route: "/access/", title: `アクセス`, isPartiallyActive: false },
    { route: "/contact/", title: "ご予約\nお問い合わせ", isPartiallyActive: false },
    { route: "/blog/", title: "コラム", isPartiallyActive: false }
  ] ) : ( [
    { route: "/en/", title: "Home", isPartiallyActive: false },
    { route: "/en/therapy/", title: "Our Therapy", isPartiallyActive: false },
    { route: "/en/profile/", title: "About Us", isPartiallyActive: false },
    { route: "/en/nishiogi/", title: "Information", isPartiallyActive: false },
    { route: "/en/access/", title: "Access", isPartiallyActive: false },
    { route: "/en/contact/", title: "Contact Us", isPartiallyActive: false }
  ]);

  const [isExpanded, toggleExpansion] = useState(false);
  const linkProps = {'activeClassName':'md:bg-c-main',
                     'partiallyActive':true,
                     'className':'block py-3  mx-2 no-underline  md:py-2 md:mx-auto  md:inline-flex md:items-center md:self-stretch md:leading-tight md:px-1',
                     'state': {'pathTop': linkToTop } };

     



  return (
  <>
    <header className={`${isExpanded ? `fixed` : `sticky top-0`} bg-c-base top-0 z-30 border-b border-white md:border-none w-full md:w-auto ` } >



      <div className="flex flex-wrap items-center justify-between w-full xl:max-w-screen-xl px-1 py-1.5 mx-auto md:px-0 md:py-0">

        {(show || !notShow ) ? 
                <Link to= {linkToTop} className="md:hidden">
                <div className="flex items-center no-underline">
      { (lang === 'ja' ) ?
                  <PenLogo className="ml-2 inline-block mr-1" width='120px' height='50px' />
        :           <PenLogoE className="ml-2 inline-block mr-1" width='120px' height='50px' />
       
                }
                </div>
              </Link>
              
          :<div className="w-[120px] h-[50px] sm:w-[300px]"></div>
   }
                           


        <div className="flex md:hidden bg-c-base">
        {(show || !notShow) ? 
<a href={`tel:${tel}`}>
          <button type="button" aria-label="Tel"
            className="text-c-sub active:text-c-base active:bg-c-sub pt-px border-c-main border-1 border-solid rounded md:hidden text-2xl  xxs:mr-4 mr-1 w-20 h-12 leading-3">
              {(lang === 'ja'? <Call className="w-4 h-4 inline-block" />
              : <Call className="w-6 h-6 inline-block fill-c-main active:fill-c-base" /> )}
            
            <span className="text-xs px-0.5">{(lang === 'ja') ? <span>お電話で<br />ご予約</span> :<span><br />Click to Call</span>}</span>
          </button></a> 
          : null }


          <button type="button" aria-label="Menu"
            className="min-w-12 width-auto text-c-sub md:hidden h-12 leading-3"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg className="h-6 w-6 fill-c-main mx-auto " viewBox="0 0 24 24">
              <path d=
                {`${isExpanded ?
                    `M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z` :
                    `M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z`
                  }`}
              />

            </svg>
            <span className="text-xs px-0.5">{(lang === 'ja') ? `${isExpanded ?  "閉じる" : "メニュー"}` :
                         `${isExpanded ?  "Close" : "Menu"}`
                }</span>
          </button>
        </div>


        <nav
          className={`${isExpanded ? `block` : `hidden`
            } sticky top-0 z-50  items-right ml-auto bg-c-base mt-1 md:mt-0 md:block md:items-center w-3/4 md:w-full md:border-b-4 md:border-t-2 md:border-c-acc`}
        ><ul className="md:flex lg:px-2 list-none space-between">
            {
              mitem.map((link) => (
                <li key={link.route} className="lg:text-lg md:whitespace-pre-wrap  
                active:bg-c-main
                hover-hover:hover:bg-c-main hover-hover:hover:text-c-base active:bg-c-main active:text-c-base md:text-center md:mx-auto md:inline-flex md:self-stretch 
                underline md:no-underline">
                  { ((link.route).includes("#"))
                  ?
                  <AnchorLink
                  to={link.route}
                  title={link.title}
                  className="block py-3  mx-2 mr-4 no-underline  text-right md:text-center md:py-2 md:mx-auto  md:inline-flex md:items-center md:self-stretch md:leading-tight md:px-1"
                  gatsbyLinkProps={linkProps}
                  stripHash
                  onAnchorLinkClick={() => toggleExpansion(!isExpanded)}

                    
                />
                :
                  <Link
                    className="block py-3  mx-2 mr-4  no-underline  text-right md:text-center md:py-2 md:mx-auto  md:inline-flex md:items-center md:self-stretch md:leading-tight md:px-1"

                    to={link.route}
                    activeClassName="md:bg-c-main md:text-c-base"
                    partiallyActive={ link.isPartiallyActive }
                    state={{ pathTop: linkToTop }} 
                    onClick={() => toggleExpansion(!isExpanded)}
                     
                  >
                    {link.title}
                  </Link>
}
                </li>
              
              ))}
          </ul>
        </nav>
      </div>

    </header>

    {isExpanded ?
    <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-1 py-1.5 mx-auto md:px-0 md:py-0">
    <PenLogo className="ml-2 inline-block mr-1" width='120px' height='50px' /></div>
    : <div /> }

          </>



  );
}

Menu.defaultProps = {
  lang: `ja`,
  pathTop: null,
  notShow: false
};

Menu.propTypes = {
  lang: PropTypes.string,
  pathTop: PropTypes.string,
  notShow: PropTypes.bool,
};


export default Menu;